<template>
  <div class="row px-5 py-2">
    <BasicPatientInfo class="opacity" :information="basicPatientInfo" />
  </div>
  <div class="row px-5 py-2">
    <PatientClinicalParametersVue
      class="opacity"
      :information="patientClinicalParameters"
    />
  </div>
  <div class="row px-5 py-2">
    <GenotypesPatientInfo class="opacity" :information="patientFenotype" />
  </div>
</template>
<script>
import GenotypesPatientInfo from "./leftCards/GenotypesPatientInfo.vue";
import BasicPatientInfo from "./leftCards/BasicPatientInfo.vue";
import PatientClinicalParametersVue from "./leftCards/PatientClinicalParameters.vue";
export default {
  name: "LeftCardsSingleFeatureInfo",
  props: {
    patientClinicalParameters: Object,
    basicPatientInfo: Object,
    patientFenotype: Object,
  },
  components: {
    PatientClinicalParametersVue,
    BasicPatientInfo,
    GenotypesPatientInfo,
  },
};
</script>

<style>
.opacity {
  background: rgba(255, 255, 255, 0.879) !important;
}
</style>
