<template>
  <div v-if="Object.keys(information).length" class="card p-3 border-0">
    <div class="d-flex flex-column align-items-start justify-content-center">
      <h5 class="text-primary py-2 fw-bold">
        Parámetros clínicos del cuadro de base del paciente
      </h5>
      <div class="w-100" v-for="(value, key) in information" :key="value">
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between p-1"
          v-if="key == 'patientActualDrugs'"
        >
          <label for="patientActualDrugs" class="fw-bold w-50 text-start"
            >Tratamiento Farmacológico actual
          </label>
          <div id="patientActualDrugs" class="w-50 ms-5 py-2">
            <button
              v-if="value.length"
              type="button"
              class="btn btn-primary py-1 px-3 ms-5"
              data-bs-toggle="modal"
              data-bs-target="#patientActualDrugsStaticBackdrop"
            >
              ver
            </button>

            <div v-else class="w-100 ms-4">
              <span class="px-1"> No refiere tratamiento farmacológico </span>
            </div>
            <!-- Modal -->
            <div
              class="modal fade"
              id="patientActualDrugsStaticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="patientActualDrugsStaticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5
                      class="modal-title"
                      id="patientActualDrugsStaticBackdropLabel"
                    >
                      Tratamiento Farmacológico actual
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div
                      v-for="patientActualDrugs in value"
                      :key="patientActualDrugs"
                      class="d-flex flex-row justify-content-center w-100 p-2"
                    >
                      <span class="fw-bold w-50 text-start"
                        >. {{ patientActualDrugs }}</span
                      >
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between p-2"
          v-else-if="key == 'patientPastDrugs'"
        >
          <label for="patientPastDrugs" class="fw-bold w-50 text-start"
            >Antecedentes Farmacológicos
          </label>
          <div id="patientPastDrugs" class="w-50 ms-5 py-2">
            <button
              v-if="value.length"
              type="button"
              class="btn btn-primary py-1 px-3 ms-5"
              data-bs-toggle="modal"
              data-bs-target="#patientPastDrugsStaticBackdrop"
            >
              ver
            </button>

            <div v-else class="w-100 ms-4">
              <span class="px-1"> No refiere antecedentes farmacológicos </span>
            </div>

            <!-- Modal -->
            <div
              class="modal fade"
              id="patientPastDrugsStaticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="patientPastDrugsStaticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5
                      class="modal-title"
                      id="patientPastDrugsStaticBackdropLabel"
                    >
                      Antecedentes Farmacológicos
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div class="modal-body">
                    <div
                      v-for="patientPastDrugs in value"
                      :key="patientPastDrugs"
                      class="d-flex flex-row justify-content-center w-100 p-2"
                    >
                      <span class="fw-bold w-50 text-start"
                        >. {{ patientPastDrugs }}</span
                      >
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between p-1"
          v-else-if="key == 'depressionSeverity' && value"
        >
          <label :for="key" class="w-75 fw-bold text-start"
            >{{ labelObject[key] }}
          </label>
          <div id="depressionSeverity" class="w-25 text-start">
            <span>{{ value }}</span>
          </div>
        </div>
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between p-1"
          v-else-if="key == 'cognitiveFunction' && value"
        >
          <label :for="key" class="w-75 fw-bold text-start"
            >{{ labelObject[key] }}
          </label>
          <div :id="key" class="w-25 text-start">
            <span class="px-3">Si</span>
          </div>
        </div>
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between p-1"
          v-else-if="key == 'sleepDisorder' && value"
        >
          <label :for="key" class="w-75 fw-bold text-start"
            >{{ labelObject[key] }}
          </label>
          <div :id="key" class="w-25 text-start me-2">
            <span class="">{{ value }}</span>
          </div>
        </div>
        

        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between p-1"
          v-else-if="key == 'suicidalIdeation' && value"
        >
          <label :for="key" class="w-75 fw-bold text-start"
            >{{ labelObject[key] }}
          </label>
          <div :id="key" class="w-25 text-start px-3">
            <span>Si</span>
          </div>
        </div>
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between p-1"
          v-else-if="key == 'bipolarSpectrum'"
        >
          <label :for="key" class="w-75 fw-bold text-start"
            >{{ labelObject[key] }}
          </label>
          <div :id="key" class="w-25 text-start px-4">
            <span v-if="value">Si</span>
            <span v-else>No</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PATIENT_CLINICAL_PARAMETERS_LABEL_NAME } from "../../../js/config";
export default {
  name: "PatientClinicalParameters",
  props: {
    information: Object,
  },
  data() {
    return {
      labelObject: PATIENT_CLINICAL_PARAMETERS_LABEL_NAME,
    };
  },
};
</script>

<style></style>