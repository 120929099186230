<template>
  <form ref="postForm" @submit.prevent="submitClick($event)">
    <div v-for="tableAttribute in tableAttributes" :key="tableAttribute.name">
      <div class="mb-3 input-group" v-if="tableAttribute.is_fk">
        <span class="input-group-text" id="inputGroup-sizing-default">{{
          tableAttribute.name
        }}</span>
        <select class="form-select" aria-label=".form-select-sm example">
          <option class="text-muted" selected hidden>
            Select an option...
          </option>
          <option
            v-for="keyOption in tableAttribute.foreignKeysArray"
            :key="keyOption"
          >
            {{ keyOption }}
          </option>
        </select>
      </div>
      <!-- int space -->
      <div v-else-if="tableAttribute.type == 'int' && !tableAttribute.is_pk">
        <div class="input-group mb-3">
          <span class="input-group-text" id="inputGroup-sizing-default">{{
            tableAttribute.name
          }}</span>
          <input
            type="number"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
          />
        </div>
      </div>
      <!-- name text space -->
      <div v-else-if="tableAttribute.type == 'str'">
        <div class="input-group mb-3">
          <span class="input-group-text" id="inputGroup-sizing-default">{{
            tableAttribute.name
          }}</span>
          <textarea
            type="text"
            class="form-control overflow-visible"
            rows="1"
            cols="50"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
          ></textarea>
        </div>
      </div>
      <div v-else-if="tableAttribute.type == 'datetime.datetime'">
        <div class="input-group mb-3">
          <span class="input-group-text" id="inputGroup-sizing-default">{{
            tableAttribute.name
          }}</span>
          <input
            type="date"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
          />
        </div>
      </div>
    <div v-if="tableAttribute.type == 'password'">
        <div class="input-group mb-3">
          <span
            class="input-group-text text-dark fw-bold"
            id="inputGroup-sizing-default"
            >Contraseña*</span
          >
          <input
            type="password"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-default"
            autocomplete="new-password"
            passwordrules="required: upper; required: lower; required: digit; 
                 minlength: 25; allowed: [-().&@?'#,/&quot;+]; max-consecutive: 2"
          />
        </div>
      </div>
      
    </div>

    <!-- button space -->
    <button class="btn btn-primary">send</button>
  </form>
</template>

<script>
export default {
  name: "GenericPostForm",
  data() {
    return {
      paswordIndex: 3,
    };
  },
  props: {
    tableAttributes: Array,
    tableName: String,
  },
  methods: {
    reset(){
      this.$refs.postForm.reset()
    },
    submitClick(event) {
      let payload = {};
      let columnIndex = 0;
      for (const attr of this.tableAttributes) {
        if (attr.name != "id") {
          if (attr.is_fk) {
            let jsonString = event.target[columnIndex].value.trim();
            let jsonInfo = JSON.parse(jsonString);
            payload[attr.name] = jsonInfo.id;
          } else if (attr.type == "datetime.datetime") {
            let dateString = event.target[columnIndex].value;
            let dateToIsoFormat = new Date(dateString).toISOString();
            payload[attr.name] = dateToIsoFormat;
          } else {
            payload[attr.name] = event.target[columnIndex].value;
          }
          columnIndex++;
        }
      }

      this.$emit("payload", payload);
      this.reset()
    },
  },
};
</script>

<style></style>
