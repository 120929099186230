<template>
  <div
    class="bg-light d-flex flex-column justify-content-center align-items-center w-100"
  >

    <div
      class="d-flex flex-row justify-content-center align-items-center w-100 h-100"
    >
      <div class="col-11 p-3 text-center" v-if="!drillDown">
        <ApexChart
          v-if="!drillDown"
    
          type="bar"
          :options="adjuvantApexChartOptions"
          :series="adjuvantApexChartData"
          @click="apexChartClickHandler"
        ></ApexChart>
      </div>
      <div class="row w-100" v-else>
        <div class="col-12">
         <div
            class="d-flex  flex-row justify-content-start align-items-center mt-3 pt-3 pb-0 mb-0"
          >
          <font-awesome-icon
            icon="arrow-left"
            class="fs-2 ms-2 btn w-25"
            @click="showAdjuvantApexChart"
          />
          <h2 class="text-muted">
            <strong>{{ adjuvantPickedName }}</strong>
          </h2>
        </div>
        </div>
     <div class="d-flex flex-row p-0  align-items-center w-100 ">
        <div class="col-6">
          <ApexChart
            id="patientAdjuvantAttributesChart"
            type="bar"
            height="600px"
            :options="adjuvantAttributesApexChartOptions"
            :series="adjuvantAttributesApexChartData"
            @click="apexChartClickHandler"
          ></ApexChart>
      
      </div>
      <div
        id="drugParameterInfoCard"
        class="border col-6 p-4 overflow-auto"
        
        v-if="!isAttributeChartLoading && drillDown && adjuvantAttributePickedName != '' && getSelectedAdjuvantAttributeName"
      >
        <div
          
          class="d-flex flex-column justify-content-center align-items-start"
        >
          <h2 class="fw-bold">
            {{ getSelectedAdjuvantAttributeName }}
          </h2>
          <p id="drugParameterInfoCardDescription" class="text-start mt-5">
     {{
              getSelectedAdjuvantAttributeDescription
            }}
          </p>
        </div>
        </div>
                  <div v-else             id="drugParameterInfoCard"
            class="border col-6 p-4 overflow-auto"            :style="{
              'background-image': `url(${require('@/assets/icononly_transparent_nobuffer_opacity.png')}),linear-gradient(
    180deg,
    rgba(150, 13, 152, 0.3393732492997199) 1%,
    rgba(0, 212, 255, 0.47102591036414565) 100%
  )`
            }"></div>
      </div>
    </div>
    </div>
  
  </div>
</template>

<script>
import { mapStores } from "pinia";
import useStore from "@/stores/index";

export default {
  name: "PatientReportAdjuvantsChart",

  data() {
    return {
      adjuvantAttributePickedName: "",
      apexKeys: [],
      apexValues: [],
      isLoading: false,
      patientId: 0,
      drillDown: false,
      apexVertical: false,
      apexWidht: 100,
      drugPickName: "",
      drugParameterName: "",
    };
  },

  computed: {
    ...mapStores(useStore),
    adjuvantApexChartOptions() {
      return {
        chart: {
          id: "patientAdjuvantChart",
          height: "auto",
           width: "100%",
          toolbar: {
            tools: {
              download: false,
            },
          },
        },
            grid: {
          show: false,
        },
            title: {
            text: "Fármacos Adyuvantes",
            align: "left",
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: "30px",
              fontWeight: "bold",
              fontFamily: undefined,
              color: "#263238",
            },
          },
                 dataLabels: {
          enabled: false,
        },
              yaxis: {
  
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 350,
            style: {
              colors: ["black"],
              fontSize: "17px",

              fontWeight: 100,
            },
          },
        },
        xaxis: {
          categories: this.getApexChartKeys,
            labels:{
            show: false
          },
      
        },
        plotOptions: {
          bar: {
            horizontal: true,
       barHeight: "80%",
          },

        },
      };
    },
    adjuvantApexChartData() {
      return [
        {
          name: "resultados",
          data: this.getApexChartValues,
        },
      ];
    },
    adjuvantAttributesApexChartOptions() {
      return {
        chart: {
          id: "patientAdjuvantAttributesChart",
         
          toolbar: {
            tools: {
              download: false,
            },
          },
        },

        xaxis: {
          categories: this.getAttributeApexChartKeys,
          labels:{
            show: false
          },
        },
                yaxis: {
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 350,
            style: {
              colors: ["black"],
              fontSize: "14px",

              fontWeight: 200,
              cssClass: "apexcharts-yaxis-label",
            },
          },
        },
                grid: {
          show: false,
        },
               dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
                  barHeight: "40%",
   
          },
        },
      };
    },
    adjuvantAttributesApexChartData() {
      return [
        {
          name: "resultados",
          data: this.getAttributeApexChartValues,
        },
      ];
    },
    isChartLoading() {
      return this.mainStore.getIsChartLoading;
    },
    getApexChartKeys() {
      return this.mainStore.getAdjuvantApexChartKeys;
    },
    getApexChartValues() {
      return this.mainStore.getAdjuvantApexChartValues;
    },
    isAttributeChartLoading() {
      return this.mainStore.getIsAttributeChartLoading;
    },
    getAttributeApexChartKeys() {
      return this.mainStore.getAdjuvantAttributeApexChartKeys;
    },
    getAttributeApexChartValues() {
      return this.mainStore.getAdjuvantAttributeApexChartValues;
    },
    getSelectedAdjuvantAttributeName() {
      return this.mainStore.getSelectedAdjuvantAttributeName;
    },
    getSelectedAdjuvantAttributeScore() {
      return this.mainStore.getSelectedAdjuvantAttributeScore;
    },
    getSelectedAdjuvantAttributeDescription() {
      return this.mainStore.getSelectedAdjuvantAttributeDescription;
    },
    getDrugParameterTitle() {
      return this.mainStore.getDrugParameterTitle;
    },
    getDrugParameterScore() {
      return this.mainStore.getDrugParameterScore;
    },
    getDrugParameterGenotype() {
      return this.mainStore.getDrugParameterGenotype;
    },
    getDrugParameterInfo() {
      return this.mainStore.getDrugParameterInfo;
    },
  },
  methods: {
    apexChartClickHandler(event, chartContext, config) {
      if (!this.drillDown) {
        this.mainStore.unSetDrugParameterTitleAndScore();
        let adjuvantPickedIndex = config?.dataPointIndex;
        this.adjuvantPickedName = this.getApexChartKeys[adjuvantPickedIndex];
        if (this.adjuvantPickedName) {
          this.drillDown = true;
          this.isLoading = true;

          this.mainStore.setAdjuvantAttributeChart(this.adjuvantPickedName);
          this.isLoading = false;
        }
      } else {
        let adjuvantAttributePickedIndex = config?.dataPointIndex;
        this.adjuvantAttributePickedName =
          this.getAttributeApexChartKeys[adjuvantAttributePickedIndex];
        if (this.adjuvantAttributePickedName) {
          this.mainStore.setSelectedAdjuvantAttributeInformation(
            this.adjuvantAttributePickedName
          );
        }else{
           this.mainStore.unsetSelectedAdjuvantAttributeInformation(
          );
        }
      }
    },

    showAdjuvantApexChart() {
      this.adjuvantPickedName = "";
      this.adjuvantAttributePickedName = "";
      this.drillDown = false;
    },
  },
};
</script>
<style scoped>
#drugParameterInfoCard {
  height: 555px;
    background: linear-gradient(
    180deg,
    rgba(150, 13, 152, 0.3393732492997199) 1%,
    rgba(0, 212, 255, 0.47102591036414565) 100%
  );
    background-repeat: no-repeat !important;
  background-size:  100% 100% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
p {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
#drugParameterInfoCardDescription{
  color: #454546 !important;
}
</style>