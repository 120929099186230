import axios from "axios";
import { BACKEND_URL } from "../config";

export async function deleteRequest(endpoint, id) {
  try {
    let response = await axios.delete(
      BACKEND_URL + endpoint + "/" + encodeURI(id)
    );
    let result = {
      success: true,
      status: response.status,
      data: response.data,
    };
    return result;
  } catch (error) {
    if (error.response) {
      // Request made and server responded
      let result = {
        success: false,
        status: error.response.status,
        data: error.response.data,
      };
      return result;
    } else if (error.request) {
      // The request was made but no response was received
      let result = { success: false, status: null, data: error.message };
      return result;
    } else {
      // Something happened in setting up the request that triggered an Error
      let result = { success: false, status: null, data: error.message };
      return result;
    }
  }
}
