<template>
  <div
    class="bg-light d-flex flex-column justify-content-center align-items-center w-100"
  >
    <div
      class="d-flex flex-row justify-content-center align-items-center w-100 h-100"
    >
      <div class="col-11 p-3 text-center" v-if="!drillDown">
        <ApexChart
          type="bar"
          :options="drugApexChartOptions"
          :series="drugApexChartData"
          @click="apexChartClickHandler"
        ></ApexChart>
      </div>
      <div class="row w-100" v-else>
        <div class="col-12">
          <div
            class="d-flex flex-row justify-content-start align-items-center mt-3 pt-3 pb-0 mb-0"
          >
            <font-awesome-icon
              icon="arrow-left"
              class="fs-2 ms-2 btn w-25"
              @click="showDrugApexChart"
            />
            <h2 class="text-muted">
              <strong>{{ drugPickedName }}</strong>
            </h2>
          </div>
        </div>
        <div class="d-flex flex-row p-0 align-items-center w-100">
          <div class="col-6">
            <ApexChart
              id="patientAdjuvantAttributesChart"
              type="bar"
              height="600px"
              :options="drugAttributesApexChartOptions"
              :series="drugAttributesApexChartData"
              @click="apexChartClickHandler"
            ></ApexChart>
          </div>

          <div
            id="drugParameterInfoCard"
            class="border col-6 p-4 overflow-auto"
           
            v-if="!isAttributeChartLoading && drillDown && drugAttributePickedName != '' && getSelectedDrugAttributeName"

          >
            <div
        
              class="d-flex flex-column justify-content-center align-items-start"
            >
              <h2 class="fw-bold">
                {{ getSelectedDrugAttributeName }}
              </h2>
              <p
                id="drugParameterInfoCardDescription"
                class="text-start mt-5 overflow-auto"
              
              >
                {{ getSelectedDrugAttributeDescription }}
              </p>
            </div>
          </div>
          <div v-else             id="drugParameterInfoCard"
            class="border col-6 p-4 overflow-auto"            :style="{
              'background-image': `url(${require('@/assets/icononly_transparent_nobuffer_opacity.png')}),linear-gradient(
    180deg,
    rgba(150, 13, 152, 0.3393732492997199) 1%,
    rgba(0, 212, 255, 0.47102591036414565) 100%
  )`
            }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import useStore from "@/stores/index";

export default {
  name: "PatientReportDrugsChart",

  data() {
    return {
      drugAttributePickedName: "",
      apexKeys: [],
      apexValues: [],
      isLoading: false,
      patientId: 0,
      drillDown: false,
      apexVertical: false,
      apexWidht: 100,
      drugPickName: "",
      drugParameterName: "",
    };
  },

  computed: {
    ...mapStores(useStore),
    drugApexChartOptions() {
      return {
        chart: {
          id: "patientDrugChart",
          height: "auto",
          width: "100%",
          toolbar: {
            tools: {
              download: false,
            },
          },
        },
        grid: {
          show: false,
        },
        title: {
          text: "Fármacos",
          align: "left",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "30px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263238",
          },
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 350,
            style: {
              colors: ["black"],
              fontSize: "17px",

              fontWeight: 100,
            },
          },
        },
        xaxis: {
          categories: this.getApexChartKeys,
          labels: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,

            barHeight: "80%",
          },
        },
      };
    },
    drugApexChartData() {
      return [
        {
          name: "resultados",
          data: this.getApexChartValues,
        },
      ];
    },
    drugAttributesApexChartOptions() {
      return {
        chart: {
          id: "patientDrugAttributesChart",

          toolbar: {
            tools: {
              download: false,
            },
          },
        },

        xaxis: {
          categories: this.getAttributeApexChartKeys,
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 350,
            style: {
              colors: ["black"],
              fontSize: "14px",

              fontWeight: 200,
              cssClass: "apexcharts-yaxis-label",
            },
          },
        },
        grid: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,

            barHeight: "40%",
          },
        },
      };
    },
    drugAttributesApexChartData() {
      return [
        {
          name: "resultados",
          data: this.getAttributeApexChartValues,
        },
      ];
    },
    isChartLoading() {
      return this.mainStore.getIsChartLoading;
    },
    getApexChartKeys() {
      return this.mainStore.getDrugApexChartKeys;
    },
    getApexChartValues() {
      return this.mainStore.getDrugApexChartValues;
    },
    isAttributeChartLoading() {
      return this.mainStore.getIsAttributeChartLoading;
    },
    getAttributeApexChartKeys() {
      return this.mainStore.getDrugAttributeApexChartKeys;
    },
    getAttributeApexChartValues() {
      return this.mainStore.getDrugAttributeApexChartValues;
    },
    getSelectedDrugAttributeName() {
      return this.mainStore.getSelectedDrugAttributeName;
    },
    getSelectedDrugAttributeScore() {
      return this.mainStore.getSelectedDrugAttributeScore;
    },
    getSelectedDrugAttributeDescription() {
      return this.mainStore.getSelectedDrugAttributeDescription;
    },
    getDrugParameterTitle() {
      return this.mainStore.getDrugParameterTitle;
    },
    getDrugParameterScore() {
      return this.mainStore.getDrugParameterScore;
    },
    getDrugParameterGenotype() {
      return this.mainStore.getDrugParameterGenotype;
    },
    getDrugParameterInfo() {
      return this.mainStore.getDrugParameterInfo;
    },
  },
  methods: {
    apexChartClickHandler(event, chartContext, config) {
      if (!this.drillDown) {
        this.mainStore.unSetDrugParameterTitleAndScore();
        let drugPickedIndex = config?.dataPointIndex;
        this.drugPickedName = this.getApexChartKeys[drugPickedIndex];
        if (this.drugPickedName) {
          this.drillDown = true;
          this.isLoading = true;

          this.mainStore.setDrugAttributeChart(this.drugPickedName);
          this.isLoading = false;
        }
      } else {
        let drugAttributePickedIndex = config?.dataPointIndex;
        this.drugAttributePickedName =
          this.getAttributeApexChartKeys[drugAttributePickedIndex];
        if (this.drugAttributePickedName) {
          this.mainStore.setSelectedDrugAttributeInformation(
            this.drugAttributePickedName
          );
        } else {
          this.mainStore.unsetSelectedDrugAttributeInformation();
        }
      }
    },

    showDrugApexChart() {
      this.drugPickedName = "";
      this.drugAttributePickedName = "";
      this.drillDown = false;
    },
  },
};
</script>
<style scoped>
#drugParameterInfoCard {
  height: 555px;
  background: linear-gradient(
    180deg,
    rgba(150, 13, 152, 0.3393732492997199) 1%,
    rgba(22, 65, 73, 0.471) 100%
  );
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
p {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
#drugParameterInfoCardDescription {
  color: #454546 !important;
}
</style>