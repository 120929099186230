import { errorHelper } from "./errorHelper";
import { getManyRequest } from "./request/getMany";

export async function getPatientAnemiaValues(patientId) {
  let endpoint = "/patient_blood_test_result";
  let filter = "q=patient_id:" + encodeURI(patientId);
  let response = await getManyRequest(endpoint, filter);
  let result = errorHelper(response);
  return result;
}
