import { errorHelper } from "../errorHelper";
import { postRequest } from "../request/post";
import { LOGIN_ENDPOINT } from "./config";
import jwt from "jsonwebtoken";
import axios from "axios";

const PUBLIC_KEY =
  "-----BEGIN PUBLIC KEY-----MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAzmdz0oJQkhMoyTjBIpLcCDCMXizZcP4a6iGBMGqU1KPEfxDoKv16JbGjDfIG2/L2GG+PaDNLbFKNatElCkZmAblozxUqt1ZlerJFCIjmL4DT/XUWNVi2N/Z2QA6J+DztvbI+cOzkNIUMCX2nRk00ryY/2hAmb2psUfZDMFXXU6aL9K2aylXKD0P4/QF20p/mAT/LeBVp4fAOtH1lu56afJChfSnXiCjERXO2pGKLhlMipnfq6Zr7gwWm6TTTQLQ/kpdCS5kgspYiJIbwJU4BM4cEt1A37yGijGGc/aZuoRH+3PrUOMDzLmWfQ6IQSnT0XEikv8kX8vNo0HRbFxS0HFTHS7zQMa/Z1MmLZPqySTcTZurmPUedIGY92fyOELFzOaEc1oGtReCxhWHxI59ERwILbYhoV1ammXO09oZmKAP2qcSIZAeGVjpQhGZ7U8EWJQDou8wqcXa8NtZKo2xxka2r9rcvXfd9mnOebJ3GWKGdHg+Tfod6zr9fxqctCE64QlEXnk1bukDji0jToznJAZixUvmWxbnim30m0Fxt1Vv+n/hMy3b2AEHP2pVuw9BE6neGWknXmP9HuGpW5UTm4sLmu+Xp8Rh6s8QY5rXXPk/QPVQoSXWP+BYxn7UykBtc+tSgw/KMcNhKlnSMf6Z/tRkALWtY7mCxZxMLoxho028CAwEAAQ==-----END PUBLIC KEY-----";
export async function postLogin(data) {
  let response = await postRequest(LOGIN_ENDPOINT, data);
  let result = errorHelper(response);
  if (result.success) {
    let token = result.data?.access_token;
    try {
      setAxiosAuthHeader(token);
      const tokenDecoded = jwt.verify(token, PUBLIC_KEY);
      return { success: true, token: tokenDecoded };
    } catch (error) {
      postLogout();
      let result = { success: false, data: error.message };
      return result;
    }
  } else {
    return result;
  }
}

export function postLogout() {
  deleteAxiosAuthHeader();
  return true;
}

function deleteAxiosAuthHeader() {
  delete axios.defaults.headers.common["Authorization"];
}

function setAxiosAuthHeader(token) {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}
