<template>
  <section class="d-flex flex-row justify-content-center align-items-top p-5">
    <div
     v-if="getPatientTherapistArray.length"
      class="d-flex flex-column justify-content-top align-items-start w-75 border p-5"
    >
      <div class="row w-100 d-flex pb-3 mb-4 border-bottom text-light">
     
            <div class=" text-center w-50 fs-3 fw-bold">
              Nombre del Paciente
            </div>
            <div class="w-50 text-center  fs-3 fw-bold">Estudio</div>
          </div>
      
      <div
        class="row p-3 w-100 fs-4"
        v-for="patientTestInfo in getPatientTherapistArray"
        :key="patientTestInfo.id"
      >
        <router-link
          class="text-white text-decoration-none p-3"
          :to="{
            name: 'PatientReport',
            params: {
              therapistId: getTherapistId,
              patientId: patientTestInfo.patient_id,
              testId: patientTestInfo.test_id,
            },
          }"
        >
          <div class="d-flex flex-row justify-content-around w-100 text-white">
            <div class=" text-start ps-2 w-75">
              {{ getPatientNameListObject[patientTestInfo?.patient_id] }}
            </div>
            <div class="w-25 text-start">{{ patientTestInfo.test_id }}</div>
          </div>
        </router-link>
      </div>
    </div>
      <div
     v-else
      class="d-flex flex-column justify-content-top align-items-start w-75 border p-5"
    >
    <div class="h-100 w-100">
      <h2 class="text-white">No se encuentran pacientes asignados a este usuario</h2>
    </div>
      </div>
  </section>
</template>

<script>
import { mapStores } from "pinia";
import useStore from "@/stores/index";

export default {
  name: "PatientListPage",
  beforeMount() {
    if(this.userLogin){
      if(this.getUser.tplist.length){
        this.setPageInitialState();
      }
  
    }else{
      this.$router.push("/")
    }
    
  },

  computed: {
    ...mapStores(useStore),
    getPatientTherapistArray() {
      return this.mainStore.getPatientTherapistArray;
    },
    getTherapistId() {
      return this.mainStore.getTherapistId;
    },
    getPatientNameListObject() {
      return this.mainStore.getPatientNameListObject;
    },
    isAdmin() {
      return this.mainStore.getIsAdmin;
    },
    userLogin() {
      return this.mainStore.userLogin;
    },
    getUser(){
      return this.mainStore.getUser;
    }
  },
  methods: {
    async setPageInitialState() {
      this.mainStore.unsetPatientTestInfo();
      this.mainStore.getDrugAndAjuvenatnList()
      await this.mainStore.setPatientTherapistArray();
 

      let patientIdArray = this.getPatientTherapistArray.map(
        (test) => test.patient_id
      );
      await this.mainStore.setPatientNameArray(patientIdArray);
    },
  },
};
</script>

<style scoped>
a:hover{
    background-color: #0099da !important;
}</style>
