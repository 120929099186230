<template>
  <div class="row px-5 py-2">
    <PatientBiologicalClinicalParameters
      class="opacity"
      :information="clinicalBiologicalParameters"
    />
  </div>
</template>

<script>

import PatientBiologicalClinicalParameters from "./rightCards/PatientBiologicalClinicalParameters.vue";
export default {
  name: "RightCardsSingleFeatureInfo",
  props: {
    clinicalBiologicalParameters: Object,
  },
  components: {
    PatientBiologicalClinicalParameters,
  },
};
</script>

<style></style>
