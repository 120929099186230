<template>
  <form ref="deleteForm" @submit.prevent="submitClick">
    <div class="mb-3 input-group pb-5">
      <span
        class="input-group-text text-danger fw-bold"
        id="inputGroup-sizing-default"
        >Record to delete</span
      >
      <select
        class="form-select"
        aria-label=".form-select example"
        v-model="record"
        @change="selectRecord($event, record)"
      >
        <option class="text-muted" selected hidden>Select an option...</option>
        <option v-for="option in tableOptions" :value="option" :key="option">
          {{ parseOption(option) }}
        </option>
      </select>
    </div>

    <div v-if="optionSelected">
      <div v-for="tableAttribute in tableAttributes" :key="tableAttribute.name">
        <div
          class="d-flex flex-row w-100 bg-white rounded mb-3 align-items-center overflow-auto"
          v-if="tableAttribute.is_fk"
        >
          <span class="input-group-text" id="inputGroup-sizing-default">{{
            tableAttribute.name
          }}</span>
          <span class="text-muted bg-white ps-2">{{
            getForeignDescription(tableAttribute)
          }}</span>
        </div>
        <!-- int space -->
        <div v-else-if="tableAttribute.type == 'int' && !tableAttribute.is_pk">
          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default">{{
              tableAttribute.name
            }}</span>
            <input
              type="number"
              class="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
              :placeholder="recordToDelete[tableAttribute.name]"
              disabled
            />
          </div>
        </div>
        <!-- name text space -->
        <div v-else-if="tableAttribute.type == 'str'">
          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default">{{
              tableAttribute.name
            }}</span>
            <textarea
              type="text"
              class="form-control bg-white"
              rows="1"
              cols="50"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
              :placeholder="recordToDelete[tableAttribute.name]"
              disabled
            ></textarea>
          </div>
        </div>
        <div v-else-if="tableAttribute.type == 'datetime.datetime'">
          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default">{{
              tableAttribute.name
            }}</span>
            <input
              type="date"
              class="form-control text-muted"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
              :value="parseDate(recordToDelete[tableAttribute.name])"
              disabled
            />
          </div>
        </div>
      </div>
      <!-- button space -->
      <button class="btn btn-primary">send</button>
    </div>
  </form>
</template>

<script>
export default {
  name: "GenericDeleteForm",
  props: {
    tableAttributes: Array,
    tableName: String,
    tableOptions: Array,
    optionSelected: Boolean,
  },
  data() {
    return {
      record: "Select an option...",
      recordToDelete: {},
      foreignKeysArrayValues: [],
    };
  },
  methods: {
    reset() {
      this.$refs.deleteForm.reset();
    },
    parseOption(option) {
      let optionClone = {...option};
      let optionToString = JSON.stringify(optionClone);
      let maxLength = 180;
      if (optionToString.length > maxLength) {
        return optionToString.substring(0, maxLength) + "...";
      } else {
        return optionToString;
      }
    },
    parseDate(date) {
      let parseDate = new Date(date)
        .toISOString()
        .replace(/T.*/, "")
        .split("/")
        .reverse()
        .join("-");
      return parseDate;
    },
    selectRecord(event, selectedRecord) {
      this.$emit("recordSelected", false);
      this.recordToDelete = selectedRecord;
      this.$emit("idToDelete", this.recordToDelete.id);
      this.$emit("recordSelected", true);
    },
    getForeignDescription(tableAttribute) {
      let value = tableAttribute.foreignKeysArray.find(
        (element) => element.id == this.recordToDelete[tableAttribute.name]
      );
      return value;
    },
    submitClick() {
      this.$emit("payload");
      this.reset();
    },
  },
};
</script>

<style scoped>
select option {
  width: 100px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}
</style>
