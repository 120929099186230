const BACKEND_URL = process.env.VUE_APP_API_URL || "http://localhost:8000";

// INDEX of the drugName on a payload.rows from view: `vw_patient_score_by_drug `
const DRUG_NAME_INDEX = 2;
const DRUG_ID_INDEX = 1;

// INDEX where the SCORE VALUES start on a payload.rows from view: `vw_patient_score_by_drug `
const DRUG_START_SCORE_VALUES_INDEX = 4;

const USER_TABLE_SPEC = [
  {
    name: "id",
    type: "int",
    is_pk: true,
    is_nullable: false,
    is_fk: false,
    has_default: false,
  },
  {
    name: "therapist_id",
    type: "int",
    is_pk: false,
    is_nullable: false,
    is_fk: true,
    has_default: false,
    _ftable: "therapist",
    _fcol: "id",
  },
  {
    name: "email",
    type: "str",
    is_pk: false,
    is_nullable: false,
    is_fk: false,
    has_default: false,
  },
  {
    name: "password",
    type: "password",
    is_pk: false,
    is_nullable: false,
    is_fk: false,
    has_default: false,
  },
];
// Relation between vw_patient_score_by_drug keys and desire real name
const DRUG_ATTRIBUTES_DICT = {
  re_score: "Rango etario",
  em_score: "Embarazo",
  pb_score: "Comorbilidades",
  se_score: "Alteraciones del sueño/alimentación",
  ps_score: "Parámetros de sangre",
  pk_score: "Farmacocinética",
  pd_score: "Farmacodinamia",
  if_score: "Interacción con otros farmacos",
};

const BASIC_PATIENT_INFO_TO_LABEL_NAME = {
  gender: "Genero",
  age: "Edad",
  pregnancy: "Embarazo",
};
const FAMILY_HISTORY_TO_LABEL_NAME = {
  familyHistoryBipolaDisorder: "Espectro bipolar",
  familyHistorySuicide: "Suicidio",
};

const PATIENT_CLINICAL_PARAMETERS_LABEL_NAME = {
  depressionSeverity: "Grado de Depresión según PHQ9",
  suicidalIdeation: "Ideas de muerte",
  cognitiveFunction: "Alteraciones Cognitivas",
  bipolarSpectrum: "Valores compatibles con espectro bipolar",
  sleepDisorder: "Alteraciones del sueño",
};
const PATIENT_BIOLOGICAL_CLINICAL_PARAMETERS_LABEL_NAME = {
  smoker: "Tabaquismo",
  bmi: "IMC",
  weightChange: "Cambio de peso",
  comorbidity: "Comorbilidades",
  familyHistory: "Antecedentes Familiares",
  anemia: "Valores compatibles con Anemia",
  hemorrhagesRisk: "Valores compatibles con Alteración de la coagulación",
  highHematocrit: "Valores compatibles con Hematocrito elevado",
  liverDysfunction: "Valores compatibles con Disfuncion hepatica",
  liverInsufficiency: "Valores compatibles con Insuficiencia hepatica",
  kidneyDysfunction: "Valores compatibles con Disfuncion renal",
  kidneyInsufficiency: "Valores compatibles con Insuficiencia renal",
  hypothyroidism: "Valores compatibles con Hipotiroidismo",
  hyperthyroidism: "Valores compatibles con Hipertiroidismo",
  hypokalemia: "Valores compatibles con Hipocalemia",
  hyponatremia: "Valores compatibles con Hiponatremia",
  leucopenia: "Valores compatibles con Leucopenia",
  venousThromboembolismRisk: "Valores compatibles con Tromboembolismo venoso",
};
// Relation between column fenotype inside vw_fenotype and its desire value
const FENOTYPE_DICT = {
  N: "Normal",
  I: "Intermedio",
  P: "Lento",
  UR: "Ultra Rapido",
};

export {
  BACKEND_URL,
  DRUG_NAME_INDEX,
  DRUG_ID_INDEX,
  DRUG_START_SCORE_VALUES_INDEX,
  DRUG_ATTRIBUTES_DICT,
  FENOTYPE_DICT,
  FAMILY_HISTORY_TO_LABEL_NAME,
  BASIC_PATIENT_INFO_TO_LABEL_NAME,
  USER_TABLE_SPEC,
  PATIENT_BIOLOGICAL_CLINICAL_PARAMETERS_LABEL_NAME,
  PATIENT_CLINICAL_PARAMETERS_LABEL_NAME,
};
