<template>
  <section class="d-flex flex-row w-100 pt-5 overflow-auto">
    <aside class="d-flex flex-column h-100 p-3">
      <div class="bg-white rounded-1 overflow-auto">
        <ul class="list-group">
          <li
            class="list-group-item btn"
            v-for="tableName in tableNameList"
            :key="tableName"
            :class="{ active: isSelected(tableName) }"
            @click="selectTable($event, tableName)"
          >
            {{ tableName }}
          </li>
        </ul>
      </div>
    </aside>

    <article class="d-flex flex-column w-75">
      <div class="d-flex flex-row text-white justify-content-center p-1">
        <h1>Table '{{ selectedTableName }}'</h1>
      </div>
      <div class="d-flex flex-row justify-content-center">
        <ul
          class="list-group list-group-horizontal d-flex flex-row justify-content-start mb-1"
        >
          <li
            
            class="btn list-group-item me-2"
            :class="{ active: isUpload }"
            @click="setIsUpload"
          >
            Upload
          </li>
          <li
            v-if="selectedTableName != 'user'"
            class="btn list-group-item me-2"
            :class="{ active: isUpdate }"
            @click="setIsUpdate"
          >
            Update
          </li>
          <li
    
            class="btn list-group-item"
            :class="{ active: isDelete }"
            @click="setIsDelete"
          >
            Delete
          </li>
        </ul>
      </div>
      <div
        v-if="selectedTableName != 'userTable'"
        class="px-3 pt-2 pb-5 overflow-auto"
      >
        <GenericPostForm
          v-if="isUpload"
          :tableAttributes="selectedTableAttributes"
          :tableName="selectedTableName"
          :optionSelected="isRecordSelected"
          @payload="postPayload"
        />
        <GenericPutForm
          v-if="isUpdate"
          :tableAttributes="selectedTableAttributes"
          :tableName="selectedTableName"
          :tableOptions="tableOptions"
          :optionSelected="isRecordSelected"
          @idToUpdate="setIdToUpdate"
          @payload="putPayload"
          @recordSelected="setRecord"
        />
        <GenericDeleteForm
          v-if="isDelete"
          :tableAttributes="selectedTableAttributes"
          :tableName="selectedTableName"
          :tableOptions="tableOptions"
          :optionSelected="isRecordSelected"
          @idToDelete="setIdToDelete"
          @payload="deletePayload"
          @recordSelected="setRecord"
        />
      </div>
    </article>
  </section>
</template>

<script>
import { mapStores } from "pinia";
import useStore from "@/stores/index";
import GenericPostForm from "@/components/Forms/GenericPostForm.vue";
import GenericPutForm from "@/components/Forms/GenericPutForm.vue";
import GenericDeleteForm from "@/components/Forms/GenericDeleteForm.vue";

export default {
  name: "AdminLanding",
  data() {
    return {
      isUpload: true,
      isUpdate: false,
      isDelete: false,
      idToDelete: 0,
      idToUpdate: 0,
      isRecordSelected: false,
    };
  },
  components: {
    GenericPostForm,
    GenericPutForm,
    GenericDeleteForm,
  },
  async beforeMount() {
    if (this.userLogin || this.isAdmin) {
      await this.selectTable("optionalEvent", "gene_combination_variant_description");
    } else {
      this.$router.push("/");
    }
  },
  computed: {
    ...mapStores(useStore),
    isAdmin() {
      return this.mainStore.getIsAdmin;
    },
    userLogin() {
      return this.mainStore.userLogin;
    },
    selectedTableName() {
      return this.mainStore.getSelectedTableName;
    },
    selectedTableAttributes() {
      return this.mainStore.getSelectedTableAttributes;
    },
    tableNameList() {
      return this.mainStore.getTableNameList;
    },
    tableOptions() {
      return this.mainStore.getTableOptions;
    },
  },
  methods: {
    setIsUpload() {
      this.isRecordSelected = false;
      this.isUpload = true;
      this.isUpdate = false;
      this.isDelete = false;
    },
    setIsUpdate() {
      this.isRecordSelected = false;
      this.isUpload = false;
      this.isUpdate = true;
      this.isDelete = false;
    },
    setIsDelete() {
      this.isRecordSelected = false;
      this.isUpload = false;
      this.isUpdate = false;
      this.isDelete = true;
    },
    setIdToUpdate(id) {
      this.idToUpdate = id;
    },
    setIdToDelete(id) {
      this.idToDelete = id;
    },
    async putPayload(payload) {
      let endpoint;
      if (this.selectedTableName == "user") {
        let error = {
          other: true,
          data: "Los usuarios solo pueden ser creados y eliminados, NO modificados",
        };
        this.mainStore.setError(error);
      } else {
        endpoint = "/" + this.selectedTableName;

        await this.mainStore.putPayload(endpoint, payload, this.idToUpdate);
        await this.selectTable("optionalEvent", this.selectedTableName);
      }
    },
    async postPayload(payload) {
      let endpoint;
      if (this.selectedTableName == "user") {
        endpoint = "/user/therapist/signup";
      } else {
        endpoint = "/" + this.selectedTableName;
      }
      await this.mainStore.postPayload(endpoint, payload);

      await this.selectTable("optionalEvent", this.selectedTableName);
    },
    async deletePayload() {
      let endpoint;
      if (this.selectedTableName == "user") {
        endpoint = "/user/therapist";
      } else {
        endpoint = "/" + this.selectedTableName;
      }
      await this.mainStore.deletePayload(endpoint, this.idToDelete);
      await this.selectTable("optionalEvent", this.selectedTableName);
    },
    isSelected(tableName) {
      if (this.selectedTableName == tableName) {
        return true;
      } else {
        return false;
      }
    },
    async selectTable(event, tableName) {
      this.isRecordSelected = false;
      await this.mainStore.selectTable(tableName);
    },
    setRecord(isSelected) {
      this.isRecordSelected = isSelected;
    },
  },
};
</script>

<style>
.active {
  background-color: aqua;
}
</style>
