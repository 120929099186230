<template>
  <div class="row">
    <div class="col">

      <LeftCardsSingleFeatureInfo
        :patientClinicalParameters="leftCardInformation.patientClinicalParameters"
        :basicPatientInfo="leftCardInformation.basicPatientInfo"
        :patientFenotype="leftCardInformation.patientFenotype"
      />
    </div>
    <div class="col">

      <RightCardsSingleFeatureInfo
        :clinicalBiologicalParameters="rightCardInformation.clinicalBiologicalParameters"

      />
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import useStore from "@/stores/index";
import LeftCardsSingleFeatureInfo from "./LeftCardsSingleFeatureInfo.vue";
import RightCardsSingleFeatureInfo from "./RightCardsSingleFeatureInfo.vue";
export default {
  name: "PatientReportInfo",
  computed: {
    ...mapStores(useStore),
    leftCardInformation() {
      return this.mainStore.getPatientAttributesLeftCards;
    },
    rightCardInformation() {
      return this.mainStore.getPatientAttributesRightCards;
    },
  },
  components: {
    LeftCardsSingleFeatureInfo,
    RightCardsSingleFeatureInfo,
  },

};
</script>

<style></style>
