import { errorHelper } from "../errorHelper";
import { getManyRequest } from "../request/getMany";
import { ENDPOINT } from "./config";

export async function getPatientTherapistList(therapistId) {
  let filter = "q=therapist_id:" + encodeURI(therapistId);
  let response = await getManyRequest(ENDPOINT, filter);
  let result = errorHelper(response);
  return result;
}
