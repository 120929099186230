<template>
  <div v-if="Object.keys(information).length" class="card p-3 border-0">
    <div class="d-flex flex-column align-items-start justify-content-center">
            <h5 class="text-primary py-2 fw-bold">Perfil farmacocinético</h5>
      <div
        v-for="(fenotypeValue, fenotypeKey) in information"
        :key="fenotypeKey"
        class="d-flex flex-row w-50 align-items-center justify-content-between p-1"
      >
        <label
          :for="fenotypeKey"
  
          class="fw-bold w-50 text-start"
          >{{ fenotypeKey }}
        </label>
        <div
          :id="fenotypeKey"
        
          class="w-50 text-start"
        >
          {{ fenotypeValue }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GenotypesPatientInfo",
  props: {
    information: Object,
  },
};
</script>

<style></style>
