<template>
  <div class="position-relative bottom-0 end-0 p-3" style="z-index: 11">
    <div class="toast show" role="status" aria-live="polite" aria-atomic="true">
      <div class="toast-header success-toast">
        <strong class="me-auto">Exito</strong>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
          @click="this.clearError"
        ></button>
      </div>
      <div class="toast-body success-toast-body">
        ¡Operación completada con exito!
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import useStore from "@/stores/index";
export default {
  name: "SuccessResult",
  props: {
    index: Number,
  },
  methods: {
    clearError() {
      this.mainStore.clearError(this.index);
    },
  },
  computed: {
    ...mapStores(useStore),
  },
};
</script>

<style scoped>
.success-toast-body {
  color: #0f5132;
}
.success-toast {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
</style>
