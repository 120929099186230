<template>
  <form ref="putForm" @submit.prevent="submitClick($event)">
    <div class="mb-3 input-group overflow-auto pb-5">
      <span
        class="input-group-text text-primary fw-bold"
        id="inputGroup-sizing-default"
        >Record to update
      </span>
      <select
        class="form-select"
        aria-label=".form-select example"
        v-model="oldRecord"
        @change="selectOldRecord($event, oldRecord)"
      >
        <option class="text-muted" selected hidden>Select an option...</option>
        <option v-for="option in tableOptions" :value="option" :key="option">
          {{ parseOption(option) }}
        </option>
      </select>
    </div>

    <div v-if="optionSelected">
      <div v-for="tableAttribute in tableAttributes" :key="tableAttribute.name">
        <div class="mb-3 input-group overflow-auto" v-if="tableAttribute.is_fk">
          <span class="input-group-text" id="inputGroup-sizing-default">{{
            tableAttribute.name
          }}</span>
          <select class="form-select" aria-label=".form-select example">
            <option selected hidden>
              {{ tableAttribute.defaultForeignKeyValue }}
            </option>
            <option
              v-for="keyOption in tableAttribute.foreignKeysArray"
              
              :key="keyOption"
            >
              {{ keyOption}}
            </option>
          </select>
        </div>
        <!-- int space -->
        <div v-else-if="tableAttribute.type == 'int' && !tableAttribute.is_pk">
          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default">{{
              tableAttribute.name
            }}</span>
            <input
              type="number"
              class="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
              v-model="recordToUpdate[tableAttribute.name]"
            />
          </div>
        </div>
        <!-- name text space -->
        <div v-else-if="tableAttribute.type == 'str'">
          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default">{{
              tableAttribute.name
            }}</span>
            <textarea
              type="text"
              class="form-control"
              rows="1"
              cols="50"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
              v-model="recordToUpdate[tableAttribute.name]"
            ></textarea>
          </div>
        </div>
        <div v-else-if="tableAttribute.type == 'datetime.datetime'">
          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default">{{
              tableAttribute.name
            }}</span>
            <input
              type="date"
              class="form-control"
              aria-label="Sizing example input"
              :value="parseDate(recordToUpdate[tableAttribute.name])"
            />
          </div>
        </div>
      </div>
      <!-- button space -->
      <button class="btn btn-primary">send</button>
    </div>
  </form>
</template>

<script>
export default {
  name: "GenericPutForm",
  props: {
    tableAttributes: Array,
    tableName: String,
    tableOptions: Array,
    optionSelected: Boolean,
  },
  data() {
    return {
      oldRecord: "Select an option...",
      recordToUpdate: {},
      oldRecordForegnKeysInfo: [],
      oldRecordByForeignKey: {},
    };
  },
  methods: {
    reset() {
      this.$refs.putForm.reset();
    },
    parseDate(date) {
      let parseDate = new Date(date)
        .toISOString()
        .replace(/T.*/, "")
        .split("/")
        .reverse()
        .join("-");
      return parseDate;
    },
        parseOption(option) {
      let optionClone = {...option};
      let optionToString = JSON.stringify(optionClone);
      let maxLength = 180;
      if (optionToString.length > maxLength) {
        return optionToString.substring(0, maxLength) + "...";
      } else {
        return optionToString;
      }
    },
    selectOldRecord(event, selectedRecord) {
      this.$emit("recordSelected", false);

      this.recordToUpdate = selectedRecord;
      this.$emit("idToUpdate", this.recordToUpdate.id);
      for (const attr of this.tableAttributes) {
        if (attr.is_fk) {
          let defaultForeignKeyValue = attr?.foreignKeysArray.find(
            (element) => element.id == this.recordToUpdate[attr.name]
          );
          attr["defaultForeignKeyValue"] = defaultForeignKeyValue;
        }
      }
      this.$emit("recordSelected", true);
    },
    submitClick(event) {
      let payload = {};
      let i = 1; // i = 0 is the index of id column on this.tableAttributes
      for (const attr of this.tableAttributes) {
        if (attr.name != "id") {
          if (attr.is_fk) {
            let jsonString = event.target[i].value.trim();
            let jsonInfo = JSON.parse(jsonString);
            payload[attr.name] = jsonInfo.id;
          } else if (attr.type == "int") {
            payload[attr.name] = parseFloat(event.target[i].value);
          } else if (attr.type == "datetime.datetime") {
            let dateString = event.target[i].value;
            let dateToIsoFormat = new Date(dateString).toISOString();
            payload[attr.name] = dateToIsoFormat;
          } else {
            payload[attr.name] = event.target[i].value;
          }
          i++;
        }
      }
      this.$emit("payload", payload);
      this.reset();
    },
  },
};
</script>

<style></style>
