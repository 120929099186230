<template>
  <header
    class="d-flex flex-row w-100 align-items-center  my-1 p-0 justify-content-between navbar border-bottom h-100"
  >
    <nav class="p-3">
      <router-link
        @click="unsetInfo"
        :to="{ name: 'LoginPage' }"
        class="text-decoration-none text-white"
        ><img src="@/assets/fulllogo_transparent_nobuffer.png" class="d-flex flex-column" alt="..." style="height: 11vh;"></router-link
      >
    </nav>
    <div class="d-flex flex-row align-items-center justify-content-end me-4 w-50">
      <div
        class="d-flex flex-row justify-content-end align-items-center  w-100"
        v-if="patientName"
      >
        <div class="d-flex flex-column justify-content-start align-items-end">
          <span class="text-white fw-bold">Paciente </span>

          <span class="text-white fw-bold">Estudio </span>

          <span class="text-white fw-bold">Profesional </span>
          <span class="text-white fw-bold">Fecha </span>
        </div>
        <div
          class="d-flex flex-column ms-4 justify-content-start align-items-start"
        >
          <span class="text-white">{{ patientName }}</span>

          <span class="text-white ">{{ testId }}</span>

          <span class="text-white ">{{ therapistName }}</span>

          <span class="text-white ">{{ testDate }}</span>
        </div>
      </div>
      <div class="ms-2 p-2 justify-content-between d-flex flex-column">
        <button
          class="btn btn-sm btn-primary mb-1"
          @click="logout"
          v-if="userLogin"
        >
          Logout
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import { mapStores } from "pinia";
import useStore from "@/stores/index";
export default {
  name: "TopHeader",
  props: {
    patientName: String,
    testId: String,
    therapistName: String,
    testDate: String,
  },
  computed: {
    ...mapStores(useStore),
    userLogin() {
      return this.mainStore.userLogin;
    },
    isAdmin() {
      return this.mainStore.getIsAdmin;
    },
    isCacheLoading(){
      return this.mainStore.getIsCacheLoading;
    }
  },
  methods: {
    unsetInfo() {
      this.mainStore.logout();
    },
    logout() {
      this.mainStore.logout();
      this.$router.push("/");
    },
    async refreshCache(){
      await this.mainStore.refreshCache();
    }
  },
};
</script>
