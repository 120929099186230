<template>
  <section
    class=""
    id="patientInfo"
    v-if="
      isPatientAttributesLoading ||
      isPatientFenotypeLoading ||
      isPatientDrugHistoryLoading
    "
  >
    <Loading class="text-white fs-5" />
  </section>

  <section v-else class=" py-4" id="patientInfo">
    <div class="p-2">
      <div class="d-flex flex-row text-white">
        <font-awesome-icon
          icon="arrow-left"
          class="fs-2 btn text-white"
          @click="pushTherapistPath"
        />
      </div>
    </div>
    <PatientReportInfo />
  </section>
  <section class="" id="patientInfo" v-if="isChartLoading">
    <Loading class="text-white fs-5" />
  </section>
  <section v-else class="" id="patientDrugChart">
    <PatientReportDrugsChart />
  </section>
  <section class="" id="patientInfo" v-if="isChartLoading">
    <Loading class="text-white fs-5" />
  </section>
  <section v-else class="" id="patientAdjuvantChart">
    <PatientReportAdjuvantsChart />
  </section>
</template>
<script>
import PatientReportInfo from "../components/PatientReportInfo/PatientReportInfo.vue";
import PatientReportDrugsChart from "@/components/PatientReportCharts/PatientReportDrugsChart.vue";
import PatientReportAdjuvantsChart from "@/components/PatientReportCharts/PatientReportAdjuvantsChart.vue";
import { mapStores } from "pinia";
import { useStore } from "@/stores";
import Loading from "../components/Loading.vue";

export default {
  name: "PatientReport",
  beforeMount() {
    if (!this.userLogin && !this.isAdmin) {
      this.$router.push("/");
    } else {
      this.setPatientReportInformation(this.patientId, this.testId);
    }
  },
  data() {
    return {
      patientId: this.$route.params.patientId,
      therapistId: this.$route.params.therapistId,
      testId: this.$route.params.testId,
    };
  },
  components: {
    PatientReportDrugsChart,
    PatientReportInfo,
    PatientReportAdjuvantsChart,
    Loading,
  },
  computed: {
    ...mapStores(useStore),
    isAdmin() {
      return this.mainStore.getIsAdmin;
    },
    userLogin() {
      return this.mainStore.userLogin;
    },
    isPatientAttributesLoading() {
      return this.mainStore.getPatientAttributesLoading;
    },
    isPatientFenotypeLoading() {
      return this.mainStore.getFenotypeIsLoading;
    },
    isPatientDrugHistoryLoading() {
      return this.mainStore.isPatientDrugHistoryLoading;
    },
    isChartLoading() {
      return this.mainStore.getIsChartLoading;
    },
  },

  methods: {
    setPatientReportInformation(patientId, testId) {
      this.mainStore.getDrugAndAjuvantTotalScores(patientId);
      this.mainStore.getPatientGender(patientId);
      this.mainStore.getPatientBloodParameter(patientId);
      this.mainStore.getPatientFenotype(patientId);
      this.mainStore.getPatientDrugsHistory(patientId);
      this.mainStore.getPatientAttributes(patientId, testId);
      this.mainStore.getPatientScoreByDrug(patientId);
    },
    pushTherapistPath() {
      this.$router.push({
        name: "PatientListPage",
        params: { therapistId: this.mainStore.getTherapistId },
      });
    },
  },
};
</script>

<style scoped>
h1 {
  margin-top: 10px;
  text-align: left;
}
h3 {
  text-align: justify;
  margin: 15px;
}
#patientInfo{
  min-height: 90vh !important;
}
#patientAdjuvantChart,#patientDrugChart{
  min-height: 100% !important;

}
</style>
