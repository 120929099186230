const CONSTRAIN_ERROR = "a foreign key constraint fails";

/**
 * This function take a request response and
 * return if success or analize the error
 * if there is one and return an error message
 * along the response
 *
 * @param {Object} response
 * @param {Boolean} response.success
 * @param {Object} response.data
 * @param {Number} response.status
 * @returns
 */
export function errorHelper(response) {
  const status = response.status;
  if (response.data == "Network Error") {
    return {
      success: false,
      data: "No es posible conectar con el servidor",
    };
  }
  switch (status) {
    case 200:
      return {
        success: true,
        data: response.data,
      };
    case 400:
      if (response.data?.message) {
        return {
          success: false,
          data: response.data.message,
        };
      }
      if (response.data?.error) {
        if (response.data.error.includes(CONSTRAIN_ERROR)) {
          return {
            success: false,
            data: "El recurso no puede ser modificado ya que otro recurso depende de el",
          };
        }
        return {
          success: false,
          data: response.data.error,
        };
      }
      return {
        success: false,
        data: response.data,
      };

    case 401:
      return {
        success: false,
        data: "Tu sesion ah expirado, porfavor ingresa denuevo",
      };

    case 403:
      return {
        success: false,
        data: "No posee los permisos necesarios",
      };

    case 404:
      return {
        success: false,
        data: "No hay concidencias para los datos ingresados",
      };

    case 409:
      return {
        success: false,
        data: "Ya existen registros con los datos ingresados",
      };

    case 413:
      return {
        success: false,
        data: "El archivo excede el tamaño permitido",
      };

    case 415:
      return {
        success: false,
        data: "El formato del archivo no es soportado",
      };

    case 420:
      return {
        success: false,
        data: response.data,
        message: response.data.detail.type,
      };

    case 500:
      return {
        success: false,
        data: "Un error ah ocurrido, por favor intente denuevo",
      };

    case 503:
      return {
        success: false,
        data: "Oops! parece que el servicio no esta disponible en este momento.",
      };

    default:
      return { success: false, data: response.data };
  }
}
