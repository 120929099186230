export function parseIntoBool(item) {
  let resultBool;
  if (item == "S") {
    resultBool = true;
  } else if (item == "N") {
    resultBool = false;
  } else {
    resultBool = null;
  }
  return resultBool;
}
