<template>
  <section
    class="d-flex flex-column justify-content-top align-items-center pt-5"
  >
    <!-- login_wrapper -->

    <form
      class="d-flex flex-column w-100 justify-content-top align-items-center p-3"
      @submit.prevent="login"
    >
      <div class="w-25 p-1"
      >
        <div class="form-group i-email">
          <input
            type="text"
            class="form-control"
            style="height:40px"
            required
            v-model="email"
            id="email2"
            placeholder="Email Address"
          />
        </div>
      </div>
      <div class="w-25 p-1">
        <div class="form-group i-password">
          <input
            type="password"
            class="form-control"
             style="height:40px"
            v-model="password"
            required=""
            id="password2"
            placeholder="Password"
            autocomplete="disabled"
          />
        </div>
      </div>

      <div class="login_btn_wrapper p-2">
        <button class="btn btn-light text-primary login_btn">Entrar</button>
      </div>
    </form>
  </section>
</template>

<script>
import { mapStores } from "pinia";
import useStore from "@/stores/index";

export default {
  name: "LoginPage",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  beforeMount(){
    this.mainStore.logout();
  },
  computed: {
    ...mapStores(useStore),
    isAdmin() {
      return this.mainStore.getIsAdmin;
    },
    userLogin() {
      return this.mainStore.userLogin;
    },
  },
  methods: {
    async login() {
      await this.mainStore.login(this.email, this.password);
      if (this.userLogin && !this.isAdmin) {
        this.pushTherapistPath();
      } else if (this.userLogin && this.isAdmin) {
        this.pushAdminPath();
      }
    },
    pushTherapistPath() {
      this.$router.push({
        name: "PatientListPage",
        params: { therapistId: this.mainStore.getTherapistId },
      });
    },
    pushAdminPath() {
      this.$router.push({
        name: "AdminLanding",
      });
    },
  },
};
</script>

<style scoped></style>
