<template>
      <TopHeader
    :patientName="getPatientName"
    :testId="getTestId"
    :testDate="getTestDate"
    :therapistName="getTherapistName"
  />
  <div class="position-absolute end-0 bottom-0">
    <RequestResult />
  </div>

  <main class="container-fluid">

    <router-view :key="$route.path" />
  </main>
</template>

<script>
import { mapStores } from "pinia";
import useStore from "@/stores/index";
import TopHeader from "./components/TopHeader.vue";
import RequestResult from "./components/ShowResult/RequestResult.vue";

export default {
  name: "App",
  beforeMount() {
    this.mainStore.logout();
  },
  computed: {
    ...mapStores(useStore),
    getPatientName() {
      return this.mainStore.getPatientAttributeHeaderInformation.patientName;
    },
    getTherapistName() {
      return this.mainStore.getPatientAttributeHeaderInformation.therapistName;
    },
    getTestId() {
      return this.mainStore.getPatientAttributeHeaderInformation.testId;
    },
    getTestDate() {
      let date = this.mainStore.getPatientAttributeHeaderInformation.testDate;
      if (date) {
  
        let parseDate = new Date(date).toISOString().replace(/T.*/, "").split("/").reverse().join("/");

        return parseDate;
      } else {
        return "";
      }
    },
  },
  components: {
    TopHeader,
    RequestResult,
  },
};
</script>

<style>
html,
body {
  margin: 0;
  scroll-behavior: smooth;
  height: 100vh;
  width: 100vw;
  padding: 0;
  overflow-x: hidden;
  font-size: 0.875rem;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: rgb(88, 9, 121);
  background: linear-gradient(
    180deg,
    rgba(88, 9, 121, 1) 0%,
    rgba(0, 212, 255, 1) 100%
  );
}

header {
  height: 14vh;
}
main {
  min-height: 100%;
  width: 100%;
  margin: 0 !important;
  padding: 0 !important;
}
section {
  min-height: 80vh !important;
  height: 100%;
  width: 100%;

}

</style>
