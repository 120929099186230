<template>
  <div class="spinner-border spinner-border-sm m-5" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style></style>
