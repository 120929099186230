import { errorHelper } from "@/js/errorHelper";
import { getManyRequest } from "../request/getMany";
import { ENDPOINT } from "./config.js";

const FINAL_ENDPOINT = ENDPOINT + "/vw_total_score";

export async function getTotalScore(patientId) {
  let filter = "q=patient_id:" + encodeURI(patientId);
  let response = await getManyRequest(FINAL_ENDPOINT, filter);
  let result = errorHelper(response);
  return result;
}
