<template>
  <div class="position-relative bottom-0 end-0 p-3" style="z-index: 11">
    <div
      class="toast show"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="toast-header error-toast text-white">
        <strong class="me-auto">Error</strong>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
          @click="this.clearError"
        ></button>
      </div>
      <div v-if="this.data?.imageResult" class="toast-body error-toast-body">
        Detalles: {{ this.data.imageResult?.data }}
      </div>
      <div v-else class="toast-body error-toast-body">
        Detalles: {{ this.data }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import useStore from "@/stores/index";
export default {
  name: "ErrorResult",
  props: {
    data: [String, Object],
    index: Number,
  },
  methods: {
    clearError() {
      this.mainStore.clearError(this.index);
    },
  },
  computed: {
    ...mapStores(useStore),
  },
};
</script>

<style scoped>
.error-toast-body {
  color: #842029 !important;
}
.error-toast {
  color: #842029 !important;
  background-color: #f8d7da !important;
  border-color: #f5c2c7;
}
</style>
