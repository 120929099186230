import { errorHelper } from "../errorHelper";
import { getManyRequest } from "../request/getMany";
import { ENDPOINT } from "./config";

const FINAL_ENDPOINT = ENDPOINT + "/vw_patient_score_by_drug";

export async function getPatientScoreByDrug(patientId) {
  let filter = "q=patient_id:" + encodeURI(patientId);
  let response = await getManyRequest(FINAL_ENDPOINT, filter);
  let result = errorHelper(response);

  return result;
}
