<template>
  <div v-if="Object.keys(information).length" class="card p-3 border-0">
    <div class="d-flex flex-column align-items-start justify-content-center">
      <h5 class="text-primary pt-2 mb-0 pb-0 fw-bold">
        Parámetros clínicos y biológicos generales del paciente
      </h5>
      <small class="text-muted pb-2 fst-italic fw-light"
        >* Ver valores en análisis de sangre.</small
      >
      <div class="w-100" v-for="(value, key) in information" :key="value">
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between p-1"
          v-if="key == 'smoker' && value"
        >
          <label :for="key" class="w-75 fw-bold text-start"
            >{{ labelObject[key] }}
          </label>
          <div :id="key" class="w-25 text-start">
            <span class="px-3">Si</span>
          </div>
        </div>
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between p-1"
          v-else-if="key == 'anemia'"
        >
          <label
            v-if="value.hasAnemia"
            for="anemia"
            class="w-75 fw-bold text-start"
            >{{ labelObject[key] }}
          </label>
          <div v-if="value.hasAnemia" id="anemia" class="w-25 text-start">
            <button
              type="button"
              class="btn btn-primary py-1 px-3"
              data-bs-toggle="modal"
              data-bs-target="#anemiaStaticBackdrop"
            >
              ver
            </button>

            <!-- Modal -->
            <div
              class="modal fade"
              id="anemiaStaticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="anemiaStaticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="anemiaStaticBackdropLabel">
                      {{ labelObject[key] }}
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div class="d-flex flex-row justify-content-around p-2">
                      <span class="fw-bold w-50 text-start ps-4"
                        >Hemoglobina</span
                      >

                      <span class="w-50 text-start">{{
                        value.hemoglobin
                      }}</span>
                    </div>
                    <div class="d-flex flex-row justify-content-around p-2">
                      <span class="fw-bold w-50 text-start ps-4"
                        >Hematocrito</span
                      >

                      <span class="w-50 text-start">{{
                        value.hematocrit
                      }}</span>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between p-1"
          v-else-if="key == 'comorbidity'"
        >
          <label
            v-if="value.length"
            for="comorbidity"
            class="w-75 fw-bold text-start"
            >{{ labelObject[key] }}
          </label>
          <div v-if="value.length" id="comorbidity" class="w-25 text-start">
            <button
              type="button"
              class="btn btn-primary py-1 px-3"
              data-bs-toggle="modal"
              data-bs-target="#comorbidityStaticBackdrop"
            >
              ver
            </button>

            <!-- Modal -->
            <div
              class="modal fade"
              id="comorbidityStaticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="comorbidityStaticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="comorbidityStaticBackdropLabel">
                      {{ labelObject[key] }}
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div
                      v-for="comorbidity in value"
                      :key="comorbidity"
                      class="d-flex flex-row justify-content-around p-2"
                    >
                      <span class="fw-bold w-50 text-start ps-4"
                        >. {{ comorbidity }}</span
                      >
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between p-1"
          v-else-if="key == 'familyHistory'"
        >
          <label
            v-if="value"
            for="familyHistory"
            class="w-75 fw-bold text-start"
            >{{ labelObject[key] }}
          </label>
          <div v-if="value" id="familyHistory" class="w-25 text-start">
            <button
              type="button"
              class="btn btn-primary py-1 px-3"
              data-bs-toggle="modal"
              data-bs-target="#familyHistoryStaticBackdrop"
            >
              ver
            </button>

            <!-- Modal -->
            <div
              class="modal fade"
              id="familyHistoryStaticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="familyHistoryStaticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5
                      class="modal-title"
                      id="familyHistoryStaticBackdropLabel"
                    >
                      {{ labelObject[key] }}
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div
                      v-for="(familyHistoryValue, familyHistoryKey) in value"
                      :key="familyHistoryKey"
                      class="d-flex flex-row justify-content-around p-2"
                    >
                      <span
                        v-if="familyHistoryValue"
                        class="fw-bold w-50 text-start ps-4"
                      >
                        .
                      </span>
                      <span class="fw-bold w-50 text-start ps-4">{{
                        familyHistoryLabelObject[familyHistoryKey]
                      }}</span>

                      <span
                        v-if="familyHistoryLabelObject[familyHistoryKey].value"
                        class="w-50 text-start"
                        >Si</span
                      >
                      <span v-else class="w-50 text-start">No</span>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between p-1"
          v-else-if="value && value == true"
        >
          <label :for="key" class="w-75 fw-bold text-start"
            >{{ labelObject[key] }}
          </label>
          <div :id="key" class="w-25 text-start">
            <span class="px-3">Si</span>
          </div>
        </div>

        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between p-1"
          v-else-if="key == 'bmi'"
        >
          <label :for="key" class="w-50 fw-bold text-start"
            >{{ labelObject[key] }}
          </label>
          <div :id="key" class="w-50 ms-5 text-center">
            <span class="ms-4 ps-2"> {{ value }}</span>
          </div>
        </div>
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between p-1"
          v-else-if="value && value != true"
        >
          <label :for="key" class="w-50 fw-bold text-start"
            >{{ labelObject[key] }}
          </label>
          <div :id="key" class="w-50 text-center">
            <span class="ms-5"> {{ value }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PATIENT_BIOLOGICAL_CLINICAL_PARAMETERS_LABEL_NAME,
  FAMILY_HISTORY_TO_LABEL_NAME,
} from "../../../js/config";
export default {
  name: "PatientBiologicalClinicalParameters",
  props: {
    information: Object,
  },
  data() {
    return {
      labelObject: PATIENT_BIOLOGICAL_CLINICAL_PARAMETERS_LABEL_NAME,
      familyHistoryLabelObject: FAMILY_HISTORY_TO_LABEL_NAME,
    };
  },
};
</script>

<style></style>
