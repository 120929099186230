<template>
  <div
    class="toast-container"
    v-for="(error, index) in this.getError"
    :key="error"
  >
    <OtherResult v-if="error?.other" :index="index" :data="error?.data" />
    <SuccessResult v-if="error?.success" :index="index" />
    <ErrorResult
      v-if="error?.success == false"
      :index="index"
      :data="error?.data"
    />
  </div>
</template>

<script>
import { mapStores } from "pinia";
import useStore from "@/stores/index";
import SuccessResult from "./SuccessResult.vue";
import OtherResult from "./OtherResult.vue";
import ErrorResult from "./ErrorResult.vue";
export default {
  name: "RequestResult",
  components: {
    OtherResult,
    SuccessResult,
    ErrorResult,
  },

  computed: {
    ...mapStores(useStore),
    getError() {
      return this.mainStore.getError;
    },
  },
};
</script>

<style scoped></style>
