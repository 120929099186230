export function isDrug(drugIdArray, drugId) {
  if (drugIdArray.find((id) => id == drugId)) {
    return true;
  }
  return false;
}

export function isAdjuvant(adjuvantIdArray, drugId) {
  if (adjuvantIdArray.find((id) => id == drugId)) {
    return true;
  }
  return false;
}
