<template>
  <div class="position-relative bottom-0 end-0 p-3" style="z-index: 11">
    <div
      class="toast show"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
      autohide
    >
      <div class="toast-header other-toast text-white">
        <strong class="me-auto">Alerta</strong>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
          @click="this.clearError"
        ></button>
      </div>
      <div class="toast-body other-toast-body">Detalles: {{ this.data }}</div>
    </div>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import useStore from "@/stores/index";
export default {
  name: "OtherResult",
  props: {
    data: String,
    index: Number,
  },
  methods: {
    clearError() {
      this.mainStore.clearError(this.index);
    },
  },
  computed: {
    ...mapStores(useStore),
  },
};
</script>

<style scoped>
.other-toast-body {
  color: #664d03 !important;
}
.other-toast {
  color: #664d03 !important;
  background-color: #fff3cd !important;
  border-color: #ffecb5 !important;
}
</style>
