<template>
  <div v-if="Object.keys(information).length" class="card p-3 border-0">
    <div class="d-flex flex-column align-items-start justify-content-center">
      <h5 class="text-primary py-2 fw-bold">Datos demográficos del paciente</h5>
      <div class="w-100" v-for="(value, key) in information" :key="value">
        <div
          class="d-flex flex-row w-100 align-items-center justify-content-between p-1"
          v-if="value && value != '0' && value != 'N'"
        >
          <label :for="key" class="w-50 fw-bold text-start"
            >{{ labelObject[key] }}
          </label>
          <div :id="key" class="w-50 text-start">
            <span>{{ value }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BASIC_PATIENT_INFO_TO_LABEL_NAME } from "@/js/config.js";
export default {
  name: "BasicPatientInfo",
  props: {
    information: Object,
  },
  data() {
    return {
      genderIndex: 0,
      ageIndex: 1,
      pregnancyIndex: 2,
      labelObject: BASIC_PATIENT_INFO_TO_LABEL_NAME,
    };
  },
};
</script>

<style></style>
