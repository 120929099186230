import { defineStore } from "pinia";
import {
  DRUG_ATTRIBUTES_DICT,
  FENOTYPE_DICT,
  USER_TABLE_SPEC,
} from "@/js/config.js";
import { getPatientScoreByDrug } from "@/js/views/vw_patient_score_by_drug";
import { getPatientTherapistList } from "@/js/patientTherapist/getPatientTherapistList";
import { getPatient } from "@/js/patient/getPatient";
import { postLogout, postLogin } from "../js/users/login";
import { getAvalibleForeignKeyList } from "../js/getAvalibleForeignIdList";
import { getManyRequest } from "../js/request/getMany";
import { postRequest } from "../js/request/post";
import { errorHelper } from "../js/errorHelper";
import { putRequest } from "../js/request/put";
import { deleteRequest } from "../js/request/delete";
import { getTotalScore } from "../js/views/vw_total_score";
import { isAdjuvant, isDrug } from "../js/getDrugType";
import { parseViewRowIntoObject } from "../js/parseView";
import { getAttributeDescriptionKey } from "../js/getAttributeDescriptionKey";
import { getPatientAttributesByTestId } from "../js/views/vw_patient_attributes";
import { getPatientFenotype } from "../js/views/vw_patient_fenotype";
import { getPatientBloodParameter } from "../js/views/vw_patient_blood_parameter";
import { parseIntoBool } from "../js/parseSNIntoBool";
import { getPatientAnemiaValues } from "../js/getPatientBloodTestResult";

export const useStore = defineStore("main", {
  state: () => {
    return {
      /* Tables */
      tablesSpecJson: {
        user: USER_TABLE_SPEC,
        attribute: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "attribute_type_id",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: true,
            has_default: false,
            _ftable: "attribute_type",
            _fcol: "id",
          },
          {
            name: "name",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "description",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        attribute_type: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "attribute_type",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
        ],
        attribute_value: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "attribute_id",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: true,
            has_default: false,
            _ftable: "attribute",
            _fcol: "id",
          },
          {
            name: "description",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        clinical_attribute_value: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "attribute_id",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: true,
            has_default: false,
            _ftable: "attribute",
            _fcol: "id",
          },
          {
            name: "description",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
        ],
        drug: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "name",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "drug_type_id",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: true,
            has_default: false,
            _ftable: "drug_type",
            _fcol: "id",
          },
          {
            name: "adjuvant",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        drug_interaction: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "drug_name",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        drug_interaction_score: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "drug_id",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: true,
            has_default: false,
            _ftable: "drug",
            _fcol: "id",
          },
          {
            name: "score",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "drug_interaction_id",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: true,
            has_default: false,
            _ftable: "drug_interaction",
            _fcol: "id",
          },
          {
            name: "notes",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        drug_type: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "type",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
        ],
        gene_combination: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "combination_id",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: true,
            has_default: false,
            _ftable: "gene_combination_variant_description",
            _fcol: "id",
          },
          {
            name: "drug_id",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: true,
            has_default: false,
            _ftable: "drug",
            _fcol: "id",
          },
          {
            name: "gene",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "fenotype",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "combination_count",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        gene_combination_variant_description: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "attribute_value_id",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: true,
            has_default: false,
            _ftable: "attribute_value",
            _fcol: "id",
          },
          {
            name: "description",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
        ],
        patient: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "patient_email",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "patient_last_name",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "patient_first_name",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "id_number",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "insurance_name",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "insurance_number",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        patient_base_pathology: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "patient_id",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: true,
            has_default: false,
            _ftable: "patient",
            _fcol: "id",
          },
          {
            name: "pathology",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
        ],
        patient_blood_test_result: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "patient_id",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: true,
            has_default: false,
            _ftable: "patient",
            _fcol: "id",
          },
          {
            name: "white_blood_cell_count",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "hemoglobin",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "hematocrit",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "neutrophils",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "eosinophils",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "basophils",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "lymphocytes",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "monocytes",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "blood_glucose",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "uremia",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "creatinine",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "total_cholesterol",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "TGO",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "TGP",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "bilirubin",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "alkaline_phosphatase",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "proteins",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "albumin",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "TSH",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "T3",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "T4L",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "triglycerides",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "cholesterol_HDL",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "cholesterol_LDL",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "platelet_count",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "prothrombin_concentration",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "RIN",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "partial_thromboplastin_time",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "sodium_level",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "potassium_level",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "kidney_dysfunction",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "kidney_insufficiency",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "liver_dysfunction",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "liver_insufficiency",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        patient_clinical_parameter: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "patient_id",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: true,
            has_default: false,
            _ftable: "patient",
            _fcol: "id",
          },
          {
            name: "gender",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "age",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "pregnancy",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "age_range",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "base_pathology",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "bipolar_disorder_fh",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "suicide_fh",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "bipolar_spectrum",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "bmi",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "sleep_disorder",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "suicidal_ideation",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "depression_severity",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "hcl32_score",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "mdq_score",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "weight_change",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "smoker",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "cognitive_function",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        patient_drug: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "patient_id",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: true,
            has_default: false,
            _ftable: "patient",
            _fcol: "id",
          },
          {
            name: "drug_interaction_id",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: true,
            has_default: false,
            _ftable: "drug_interaction",
            _fcol: "id",
          },
          {
            name: "actual",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        patient_genetic_variant: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "patient_id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: true,
            has_default: false,
            _ftable: "patient",
            _fcol: "id",
          },
          {
            name: "gene_deletion",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs1057910",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs1065852",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs1135840",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs1135840xN",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs12248560",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs16947",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs16947xN",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs17884712",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs1799853",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs2069514",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs2069526",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs2279343",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs28371685",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs28371686",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs28371706",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs28371725",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs28399499",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs28399504",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs35599367",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs35694136",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs35742686",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs3745274",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs3892097",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs41291556",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs4244285",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs4986893",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs5030655",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs5030656",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs5030865",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs5030867",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs56337013",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs6413438",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs72552267",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs72558186",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs762551",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "rs9332131",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        patient_genetic_variation: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "patient_id",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: true,
            has_default: false,
            _ftable: "patient",
            _fcol: "id",
          },
          {
            name: "gen_type",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "gen",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "variant",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "allele",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
        ],
        patient_pd_scoring: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "patient_id",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: true,
            has_default: false,
            _ftable: "patient",
            _fcol: "id",
          },
          {
            name: "drug_id",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: true,
            has_default: false,
            _ftable: "drug",
            _fcol: "id",
          },
          {
            name: "category",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "max_count",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "sum_score",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "score",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        patient_therapist: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "patient_id",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: true,
            has_default: false,
            _ftable: "patient",
            _fcol: "id",
          },
          {
            name: "therapist_id",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: true,
            has_default: false,
            _ftable: "therapist",
            _fcol: "id",
          },
          {
            name: "test_id",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "test_date",
            type: "datetime.datetime",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        pd_combination: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "drug_id",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: true,
            has_default: false,
            _ftable: "drug",
            _fcol: "id",
          },
          {
            name: "gene",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "variant",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "allele",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "score",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "count",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "category",
            type: "str",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "description",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        score: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "attribute_id",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: true,
            has_default: false,
            _ftable: "attribute",
            _fcol: "id",
          },
          {
            name: "drug_id",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: true,
            has_default: false,
            _ftable: "drug",
            _fcol: "id",
          },
          {
            name: "attribute_value_id",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "score",
            type: "int",
            is_pk: false,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "description",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        side_effect_combination: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "wc",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "bmi",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "sd",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "side_effect_ranking",
            type: "int",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        therapist: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "therapist_email",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "therapist_last_name",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "therapist_first_name",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
        weight: [
          {
            name: "id",
            type: "int",
            is_pk: true,
            is_nullable: false,
            is_fk: false,
            has_default: false,
          },
          {
            name: "pb_weight",
            type: "float",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "em_weight",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "re_weight",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "pk_weight",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "ps_weight",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "pd_weight",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
          {
            name: "es_weight",
            type: "str",
            is_pk: false,
            is_nullable: true,
            is_fk: false,
            has_default: false,
          },
        ],
      },
      selectedTableName: "",
      selectedTableAttributes: [],
      tableOptions: [],
      /* Error State */
      errorArray: [],

      // User
      isCacheLoading: false,
      isAdmin: false,
      userInfo: null,

      // Therapist
      therapistId: null,
      therapistName: "",

      // List of Patients page
      patientArray: [],
      patientNameObjectById: {},

      //Patient card information
      // DRUG HISTORY
      isPatientDrugHistoryLoading: true,
      patientActualDrugs: [],
      patientPastDrugs: [],

      // FENOTYPE
      isPatientFenotypeLoading: true,
      patientFenotypeDict: {},

      // ATTRIBUTES
      isPatientAttributesLoading: true,
      testDate: "",
      testId: "",
      patientFullName: "",
      therapistFullName: "",
      depressionSeverity: "",
      suicidalIdeation: false,
      sleepDisorder: "",
      cognitiveFunction: false,
      bipolarSpectrum: false,
      age: "",
      pregnancy: "",
      gender: "",
      smoker: false,
      comorbidity: "",
      bmi: "",
      weightChange: "",
      familyHistoryBipolaDisorder: false,
      familyHistorySuicide: false,

      // BLOOD PARAMETERS
      isPatientBloodParameterLoading: true,
      leucopenia: false,
      anemia: false,
      hematocrit: "",
      hemoglobin: "",
      hemorrhagesRisk: false,
      highHematocrit: false,
      hyperthyroidism: false,
      hypokalemia: false,
      hyponatremia: false,
      hypothyroidism: false,
      kidneyDysfunction: false,
      kidneyInsufficiency: false,
      liverDysfunction: false,
      liverInsufficiency: false,
      venousThromboembolismRisk: false,

      // Objects to relationate id with drugName { drugName: id }
      adjuvantDict: {},
      drugDict: {},

      // Array of Names used to differenciate drugChart from adjuvantChart
      adjuvantIdArray: [],
      drugIdArray: [],

      // Main Charts Info {drugName : totalScore}
      isChartLoading: true,
      adjuvantApexChart: {},
      drugApexChart: {},

      // Attribute Charts Info {attr: attrScore}
      isAttributeChartLoading: true,
      adjuvantAttributeApexChart: {},
      drugAttributeApexChart: {},

      // Drugs and Adjuvants AttributesScore { drugId: {attr : attrScore}}
      scoreByDrugIdDict: {},

      // Drugs and Adjuvants AttributesNames { attrKey: attrName}
      drugAttributesKeysDict: DRUG_ATTRIBUTES_DICT,

      // MAIN Charts interactions
      selectedDrugIndexApexChart: null,
      selectedAdjuvantIndexApexChart: null,
      selectedDrugName: "",
      selectedDrugId: 0,
      selectedAdjuvantName: "",
      selectedAdjuvantId: 0,

      //ATTRIBUTE Charts interaction
      selectedDrugAttributeName: "",
      selectedDrugAttributeScore: 0,
      selectedDrugAttributeDescription: "",
      selectedAdjuvantAttributeName: "",
      selectedAdjuvantAttributeScore: 0,
      selectedAdjuvantAttributeDescription: "",

      // Patient Drug Parameter Info
      drugParameterTitle: "",
      drugParameterScore: "",
      drugParameterGenotype: "",
      drugParameterInfo: "",
    };
  },
  actions: {
    async deletePayload(endpoint, id) {
      let response = await deleteRequest(endpoint, id);
      let result = errorHelper(response);
      this.setError(result);
    },
    async putPayload(endpoint, payload, id) {
      let response = await putRequest(endpoint, payload, id);
      let result = errorHelper(response);
      this.setError(result);
    },
    async postPayload(endpoint, payload) {
      let response = await postRequest(endpoint, payload);
      let result = errorHelper(response);
      this.setError(result);
    },
    async selectUserTable(tableName) {
      this.selectedTableName = tableName;
      let filter = "";
      let resultArray = await getManyRequest("/therapist", filter);
      if (resultArray.success) {
        let tableOptions = resultArray.data;
        this.tableOptions = tableOptions;
      }
    },
    async selectTable(tableName) {
      let endpoint;
      this.selectedTableName = tableName;
      this.selectedTableAttributes = this.tablesSpecJson[tableName];
      let i = 0;
      let filter = "limit=20000";

      if (tableName == "user") {
        endpoint = "/user/therapist";
      } else {
        endpoint = "/" + tableName;
      }
      let resultArray = await getManyRequest(endpoint, filter);
      if (resultArray.success) {
        let tableOptions = resultArray.data;
        this.tableOptions = tableOptions;
      }
      for (const tableAttribute of this.selectedTableAttributes) {
        if (tableAttribute.is_fk) {
          let result = await getAvalibleForeignKeyList(tableAttribute._ftable);
          if (result.success) {
            this.selectedTableAttributes[i]["foreignKeysArray"] = result.data;
          }
        }
        i++;
      }
    },
    clearError(errorIndex) {
      this.errorArray.splice(errorIndex, 1);
    },
    clearErrorAfterTime() {
      this.errorArray.shift();
    },
    setError(error) {
      if (this.errorArray.length > 3) {
        this.errorArray.shift();
      }
      this.errorArray.push(error);
      setTimeout(this.clearErrorAfterTime, 1500);
    },
    unsetTherapistInfo() {
      this.therapistId = null;
    },
    unsetPatientTestInfo() {
      this.patientFullName = "";
      this.therapistFullName = "";
      this.gender = "";
      this.testDate = "";
      this.testId = "";
      this.patientActualDrugs = [];
      this.patientPastDrugs = [];
      this.patientFenotypeDict = {};
      this.leucopenia = false;
      this.anemia = false;
      this.hemorrhagesRisk = false;
      this.highHematocrit = false;
      this.hyperthyroidism = false;
      this.hypokalemia = false;
      this.hyponatremia = false;
      this.hypothyroidism = false;
      this.kidneyDysfunction = false;
      this.kidneyInsufficiency = false;
      this.liverDysfunction = false;
      this.liverInsufficiency = false;
      this.venousThromboembolismRisk = false;
      this.pregnancy = "";
      this.depressionSeverity = "";
      this.suicidalIdeation = false;
      this.sleepDisorder = "";
      this.cognitiveFunction = false;
      this.bipolarSpectrum = false;
      this.age = "";
      this.familyHistoryBipolaDisorder = false;
      this.familyHistorySuicide = false;
      this.smoker = false;
      this.comorbidity = "";
      this.bmi = "";
      this.weightChange = "";
    },
    async login(email, password) {
      let data = {
        email: email,
        password: password,
      };
      let result = await postLogin(data);
      if (result.success) {
        let user = result.token;
        if (user.utype == "admin") {
          this.isAdmin = true;
        } else if (user.utype == "therapist") {
          this.isAdmin = false;
          this.setTherapistId(user.sub);
        }
        this.userInfo = user;
      } else {
        this.setError(result);
      }
    },
    logout() {
      postLogout();

      this.unsetTherapistInfo();
      this.unsetPatientTestInfo();
      this.userInfo = null;
      this.isAdmin = false;
      this.therapistName = "";
      this.patientArray = [];
      this.patientNameObjectById = {};
    },
    unSetDrugParameterTitleAndScore() {
      this.drugParameterTitle = "";
      this.drugParameterScore = "";
    },
    setTherapistId(therapistId) {
      this.therapistId = therapistId;
    },
    async setPatientTherapistArray() {
      let result = await getPatientTherapistList(this.therapistId);
      if (result.success) {
        this.patientArray = result.data;
      } else {
        this.setError(result);
      }
    },
    async setPatientNameArray(patientIdArray) {
      let resultArray = [];
      let patientNameFinalList = {};
      let i = 0;
      for (const patientId of patientIdArray) {
        resultArray.push(getPatient(patientId));
      }
      let resolveResultArray = await Promise.all(resultArray);
      for (const result of resolveResultArray) {
        if (result.success) {
          let patient = result.data;
          let patientName =
            patient.patient_last_name + " " + patient.patient_first_name;
          patientNameFinalList[patientIdArray[i]] = patientName;
        } else {
          this.setError(result);
        }
        i++;
      }
      this.patientNameObjectById = patientNameFinalList;
    },

    async getPatientDrugsHistory(patientId) {
      this.isPatientDrugHistoryLoading = true;
      let drugInteractionEndpoint = "/drug_interaction";
      let endpoint = "/patient_drug";
      let filter = "q=patient_id:" + encodeURI(patientId);
      let response = await getManyRequest(endpoint, filter);
      let result = errorHelper(response);
      if (result.success) {
        let patientActualDrugsRequestArray = [];
        let patientPastDrugsRequestArray = [];
        let patietnDrugHistory = result.data;
        for (const drug of patietnDrugHistory) {
          if (drug.actual) {
            let filter = "q=id:" + encodeURI(drug["drug_interaction_id"]);
            let response = getManyRequest(drugInteractionEndpoint, filter);
            patientActualDrugsRequestArray.push(response);
          } else {
            let filter = "q=id:" + encodeURI(drug["drug_interaction_id"]);
            let response = getManyRequest(drugInteractionEndpoint, filter);
            patientPastDrugsRequestArray.push(response);
          }
        }

        let patientActualDrugsResponseArray = await Promise.all(
          patientActualDrugsRequestArray
        );
        let patientPastDrugsResponseArray = await Promise.all(
          patientPastDrugsRequestArray
        );
        let patientActualDrugsArray = [];
        let patientPastDrugsArray = [];
        for (const drugResponse of patientActualDrugsResponseArray) {
          let result = errorHelper(drugResponse);
          if (result.success) {
            let drugName = result.data?.[0]?.["drug_name"];
            patientActualDrugsArray.push(drugName);
          } else {
            this.setError(result);
          }
        }
        for (const drugResponse of patientPastDrugsResponseArray) {
          let result = errorHelper(drugResponse);
          if (result.success) {
            let drugName = result.data?.[0]?.["drug_name"];
            patientPastDrugsArray.push(drugName);
          } else {
            this.setError(result);
          }
        }
        this.patientActualDrugs = patientActualDrugsArray;
        this.patientPastDrugs = patientPastDrugsArray;
      } else {
        this.setError(result);
      }
      this.isPatientDrugHistoryLoading = false;
    },
    async getPatientFenotype(patientId) {
      this.isPatientFenotypeLoading = true;
      let result = await getPatientFenotype(patientId);
      if (result.success) {
        let patientFenotypeKeys = result.data?.keys;
        let patientFenotypeValues = result.data?.rows;
        let patientFenotypeDict = {};
        for (const rowValues of patientFenotypeValues) {
          if (rowValues) {
            let rowObject = parseViewRowIntoObject(
              patientFenotypeKeys,
              rowValues
            );
            patientFenotypeDict[rowObject["gene"]] =
              FENOTYPE_DICT[rowObject["fenotype"]];
          }
          this.patientFenotypeDict = patientFenotypeDict;
        }
      } else {
        this.setError(result);
      }
      this.isPatientFenotypeLoading = false;
    },
    async getPatientGender(patientId) {
      let filter = "q=patient_id:" + encodeURI(patientId);
      let endpoint = "/patient_clinical_parameter";
      let response = await getManyRequest(endpoint, filter);
      let result = errorHelper(response);
      if (result.success) {
        let gender = result.data?.[0]?.gender;
        if (gender == "F") {
          gender = "Femenino";
        } else if (gender == "M") {
          gender = "Masculino";
        } else {
          gender = "Indefinido";
        }
        this.gender = gender;
      } else {
        this.setError(result);
      }
    },
    async getPatientBloodParameter(patientId) {
      this.isPatientBloodParameterLoading = true;
      let result = await getPatientBloodParameter(patientId);
      if (result.success) {
        let patientBloodParameterKeys = result.data?.keys;
        let rowValues = result.data?.rows?.[0];
        if (rowValues) {
          let rowObject = parseViewRowIntoObject(
            patientBloodParameterKeys,
            rowValues
          );
          this.leucopenia = parseIntoBool(rowObject["Leucopenia"]) ?? false;
          this.anemia = parseIntoBool(rowObject["anemia"]) ?? false;
          if (this.anemia) {
            let resultAnemiaValues = await getPatientAnemiaValues(patientId);
            if (resultAnemiaValues.success) {
              let data = resultAnemiaValues.data?.[0];
              this.hematocrit = data?.hematocrit ?? "Not Found";
              this.hemoglobin = data?.hemoglobin ?? "Not Found";
            }
          }
          this.hemorrhagesRisk =
            parseIntoBool(rowObject["hemorrhages_risk"]) ?? false;
          this.highHematocrit =
            parseIntoBool(rowObject["high_hematocrit"]) ?? false;
          this.hyperthyroidism =
            parseIntoBool(rowObject["hyperthyroidism"]) ?? false;
          this.hypokalemia = parseIntoBool(rowObject["hypokalemia"]) ?? false;
          this.hyponatremia = parseIntoBool(rowObject["hyponatremia"]) ?? false;
          this.hypothyroidism =
            parseIntoBool(rowObject["hypothyroidism"]) ?? false;
          this.kidneyDysfunction =
            parseIntoBool(rowObject["kidney_dysfunction"]) ?? false;
          this.kidneyInsufficiency =
            parseIntoBool(rowObject["kidney_insufficiency"]) ?? false;
          this.liverDysfunction =
            parseIntoBool(rowObject["liver_dysfunction"]) ?? false;
          this.liverInsufficiency =
            parseIntoBool(rowObject["liver_insufficiency"]) ?? false;
          this.venousThromboembolismRisk =
            parseIntoBool(rowObject["venous_thromboembolism_risk"]) ?? false;
        }
      } else {
        this.setError(result);
      }
      this.isPatientBloodParameterLoading = false;
    },
    async getPatientAttributes(patientId, testId) {
      this.isPatientAttributesLoading = true;
      let result = await getPatientAttributesByTestId(patientId, testId);
      if (result.success) {
        let patientAttributeKeys = result.data?.keys;
        let patientAttributeValues = result.data?.rows;
        for (const rowValues of patientAttributeValues) {
          if (rowValues) {
            let rowObject = parseViewRowIntoObject(
              patientAttributeKeys,
              rowValues
            );
            this.testDate = rowObject["test_date"] ?? "";
            this.testId = rowObject["test_id"] ?? "";
            this.patientFullName =
              rowObject["patient_first_name"] +
              " " +
              rowObject["patient_last_name"];
            this.therapistFullName =
              rowObject["therapist_first_name"] +
              " " +
              rowObject["therapist_last_name"];
            this.pregnancy = rowObject["pregnancy"] ?? "";
            this.depressionSeverity = rowObject["depression_severity"] ?? "";
            this.suicidalIdeation =
              parseIntoBool(rowObject["suicidal_ideation"]) ?? false;
            this.sleepDisorder = rowObject["sleep_disorder"] ?? "";
            this.cognitiveFunction =
              parseIntoBool(rowObject["cognitive_function"]) ?? false;
            this.bipolarSpectrum =
              parseIntoBool(rowObject["bipolar_spectrum"]) ?? false;
            this.age = rowObject["age"] ?? "";
            this.familyHistoryBipolaDisorder =
              parseIntoBool(rowObject["bipolar_disorder_fh"]) ?? false;
            this.familyHistorySuicide =
              parseIntoBool(rowObject["suicide_fh"]) ?? false;
            this.smoker = parseIntoBool(rowObject["smoker"]) ?? false;
            this.comorbidity = rowObject["comorbidity"] ?? "";
            this.bmi = rowObject["bmi"] ?? "";
            this.weightChange = rowObject["weight_change"] ?? "";
          }
        }
      } else {
        this.setError(result);
      }
      this.isPatientAttributesLoading = false;
    },
    unsetSelectedDrugAttributeInformation() {
      this.selectedDrugAttributeName = "";
      this.selectedDrugAttributeScore = 0;
      this.selectedDrugAttributeDescription = "";
    },
    setSelectedDrugAttributeInformation(drugAttributeName) {
      let attributeDescriptionKey =
        getAttributeDescriptionKey(drugAttributeName);
      this.selectedDrugAttributeName = drugAttributeName;
      this.selectedDrugAttributeScore =
        this.drugAttributeApexChart[drugAttributeName];
      this.selectedDrugAttributeDescription =
        this.scoreByDrugIdDict[this.selectedDrugId][attributeDescriptionKey];
    },

    setDrugAttributeChart(drugName) {
      this.isAttributeChartLoading = true;
      let drugId = this.drugDict[drugName];
      let drugDict = this.scoreByDrugIdDict[drugId];
      let drugAttributeDict = {};
      for (const attributeName of Object.keys(this.drugAttributesKeysDict)) {
        let attributeFullName = this.drugAttributesKeysDict[attributeName];
        drugAttributeDict[attributeFullName] = drugDict[attributeName];
      }
      this.drugAttributeApexChart = drugAttributeDict;
      this.selectedDrugId = drugId;
      this.selectedDrugName = drugName;
      this.isAttributeChartLoading = false;
    },
    unsetSelectedAdjuvantAttributeInformation() {
      this.selectedAdjuvantAttributeName = "";
      this.selectedAdjuvantAttributeScore = 0;
      this.selectedAdjuvantAttributeDescription = "";
    },
    setSelectedAdjuvantAttributeInformation(adjuvantAttributeName) {
      let attributeDescriptionKey = getAttributeDescriptionKey(
        adjuvantAttributeName
      );
      this.selectedAdjuvantAttributeName = adjuvantAttributeName;
      this.selectedAdjuvantAttributeScore =
        this.adjuvantAttributeApexChart[adjuvantAttributeName];
      this.selectedAdjuvantAttributeDescription =
        this.scoreByDrugIdDict[this.selectedAdjuvantId][
          attributeDescriptionKey
        ];
    },
    setAdjuvantAttributeChart(adjuvantName) {
      this.isAttributeChartLoading = true;
      let adjuvantId = this.adjuvantDict[adjuvantName];
      let adjuvantDict = this.scoreByDrugIdDict[adjuvantId];
      let adjuvantAttributeDict = {};
      for (const attributeName of Object.keys(this.drugAttributesKeysDict)) {
        let attributeFullName = this.drugAttributesKeysDict[attributeName];
        adjuvantAttributeDict[attributeFullName] = adjuvantDict[attributeName];
      }
      this.adjuvantAttributeApexChart = adjuvantAttributeDict;
      this.selectedAdjuvantId = adjuvantId;
      this.selectedAdjuvantName = adjuvantName;
      this.isAttributeChartLoading = false;
    },
    async getDrugAndAjuvenatnList() {
      let adjuvantIdArray = [];
      let drugIdArray = [];
      let drugDict = {};
      let adjuvantDict = {};
      let filter = "";
      let endpoint = "/drug";

      let response = await getManyRequest(endpoint, filter);
      let result = errorHelper(response);
      if (result.success) {
        let drugArray = result.data;
        for (const drug of drugArray) {
          if (drug.adjuvant) {
            adjuvantIdArray.push(drug.id);
            adjuvantDict[drug.name] = drug.id;
          } else {
            drugIdArray.push(drug.id);
            drugDict[drug.name] = drug.id;
          }
        }
        this.drugIdArray = drugIdArray;
        this.adjuvantIdArray = adjuvantIdArray;
        this.drugDict = drugDict;
        this.adjuvantDict = adjuvantDict;
      } else {
        this.setError(result);
      }
    },
    async getDrugAndAjuvantTotalScores(patientId) {
      this.isChartLoading = true;
      let result = await getTotalScore(patientId);
      if (result.success) {
        let totalScoreRows = result.data?.rows;
        let totalScoreKeys = result.data?.keys;
        let totalDrugScoreDict = {};
        let totalAdjuvantScoreDict = {};
        for (const rowValues of totalScoreRows) {
          if (rowValues) {
            let rowObject = parseViewRowIntoObject(totalScoreKeys, rowValues);
            if (rowObject["drug_name"]) {
              if (isDrug(this.drugIdArray, rowObject["drug_id"])) {
                totalDrugScoreDict[rowObject["drug_name"]] = Math.round(
                  rowObject["total"] ?? 0
                );
              }
              if (isAdjuvant(this.adjuvantIdArray, rowObject["drug_id"])) {
                totalAdjuvantScoreDict[rowObject["drug_name"]] = Math.round(
                  rowObject["total"] ?? 0
                );
              }
            }
          }
        }
        this.adjuvantApexChart = totalAdjuvantScoreDict;
        this.drugApexChart = totalDrugScoreDict;
      } else {
        this.setError(result);
      }
      this.isChartLoading = false;
    },
    async getPatientScoreByDrug(patientId) {
      this.isAttributeChartLoading = true;
      let result = await getPatientScoreByDrug(patientId);
      if (result.success) {
        let scoreByDrugIdDict = {};
        let scoreByDrugRows = result.data?.rows;
        let scoreByDrugKeys = result.data?.keys;
        for (const rowValues of scoreByDrugRows) {
          if (rowValues) {
            let rowObject = parseViewRowIntoObject(scoreByDrugKeys, rowValues);

            if (rowObject["drug_name"]) {
              scoreByDrugIdDict[rowObject["drug_id"]] = rowObject;
            }
          }
        }
        this.scoreByDrugIdDict = scoreByDrugIdDict;
      } else {
        this.setError(result);
      }
      this.isAttributeChartLoading = false;
    },
  },
  getters: {
    //GET MAIN CHARTS INFORMATION

    getIsChartLoading() {
      return this.isChartLoading;
    },
    // 1.DRUG CHART
    getDrugApexChartKeys() {
      let keys = Object.keys(this.drugApexChart);
      return keys;
    },
    getDrugApexChartValues() {
      let values = Object.values(this.drugApexChart);
      return values;
    },
    // 2.ADJUVANT CHART
    getAdjuvantApexChartKeys() {
      let keys = Object.keys(this.adjuvantApexChart);
      return keys;
    },
    getAdjuvantApexChartValues() {
      let values = Object.values(this.adjuvantApexChart);
      return values;
    },

    // GET ATTRIBUTES CHART INFORMATION
    getIsAttributeChartLoading() {
      return this.isAttributeChartLoading;
    },
    // 1.DRUG CHART
    getSelectedDrugAttributeName() {
      return this.selectedDrugAttributeName;
    },
    getSelectedDrugAttributeScore() {
      return this.selectedDrugAttributeScore;
    },
    getSelectedDrugAttributeDescription() {
      return this.selectedDrugAttributeDescription;
    },
    getDrugAttributeApexChartKeys() {
      let keys = Object.keys(this.drugAttributeApexChart);
      return keys;
    },
    getDrugAttributeApexChartValues() {
      let values = Object.values(this.drugAttributeApexChart);
      return values;
    },

    // 2.ADJUVANT CHART
    getSelectedAdjuvantAttributeName() {
      return this.selectedAdjuvantAttributeName;
    },
    getSelectedAdjuvantAttributeScore() {
      return this.selectedAdjuvantAttributeScore;
    },
    getSelectedAdjuvantAttributeDescription() {
      return this.selectedAdjuvantAttributeDescription;
    },
    getAdjuvantAttributeApexChartKeys() {
      let keys = Object.keys(this.adjuvantAttributeApexChart);
      return keys;
    },
    getAdjuvantAttributeApexChartValues() {
      let values = Object.values(this.adjuvantAttributeApexChart);
      return values;
    },

    //PATIENT ATTRIBUTE HEADER INFORMATION
    getPatientAttributeHeaderInformation() {
      let testDate = this.testDate;
      let headerInformation = {
        patientName: this.patientFullName,
        therapistName: this.therapistFullName,
        testDate: testDate,
        testId: this.testId,
      };
      return headerInformation;
    },
    // PATIENT ATTRIBUTE CARDS INFORMATION
    // Loadings
    // DRUG HISTORY
    getDrugHistoryIsLoading() {
      return this.isPatientDrugHistoryLoading;
    },
    // FENOTYPE
    getFenotypeIsLoading() {
      return this.isPatientFenotypeLoading;
    },
    // ATTRIBUTES
    getPatientAttributesLoading() {
      if (
        this.isPatientBloodParameterLoading ||
        this.isPatientAttributesLoading
      ) {
        return true;
      } else {
        return false;
      }
    },
    // 1. LEFT CARDS
    getPatientAttributesLeftCards() {
      let basicPatientInfo = {
        gender: this.gender,
        age: this.age,
        pregnancy: this.pregnancy,
      };

      let patientClinicalParameters = {
        depressionSeverity: this.depressionSeverity,
        suicidalIdeation: this.suicidalIdeation,
        sleepDisorder: this.sleepDisorder,
        cognitiveFunction: this.cognitiveFunction,
        patientActualDrugs: this.patientActualDrugs,
        patientPastDrugs: this.patientPastDrugs,
        bipolarSpectrum: this.bipolarSpectrum,
      };
      let patientFenotype = this.patientFenotypeDict;
      return {
        basicPatientInfo: basicPatientInfo,
        patientClinicalParameters: patientClinicalParameters,
        patientFenotype: patientFenotype,
      };
    },
    // 2. RIGHT CARDS
    getPatientAttributesRightCards() {
      let comorbidity;
      if (this.comorbidity) {
        comorbidity = this.comorbidity.split(",");
      } else {
        comorbidity = [];
      }
      let familyHistory = {
        familyHistoryBipolaDisorder: this.familyHistoryBipolaDisorder,
        familyHistorySuicide: this.familyHistorySuicide,
      };
      let clinicalBiologicalParameters = {
        smoker: this.smoker,
        familyHistory: familyHistory,
        comorbidity: comorbidity,
        bmi: this.bmi,
        weightChange: this.weightChange,
        anemia: {
          hasAnemia: this.anemia,
          hematocrit: this.hematocrit,
          hemoglobin: this.hemoglobin,
        },
        leucopenia: this.leucopenia,
        highHematocrit: this.highHematocrit,
        liverDysfunction: this.liverDysfunction,
        liverInsufficiency: this.liverInsufficiency,
        kidneyDysfunction: this.kidneyDysfunction,
        kidneyInsufficiency: this.kidneyInsufficiency,
        hyperthyroidism: this.hyperthyroidism,
        hypothyroidism: this.hypothyroidism,
        hemorrhagesRisk: this.hemorrhagesRisk,
        venousThromboembolismRisk: this.venousThromboembolismRisk,
        hyponatremia: this.hyponatremia,
        hypokalemia: this.hypokalemia,
      };

      return {
        clinicalBiologicalParameters: clinicalBiologicalParameters,
      };
    },
    getDrugParameterTitle() {
      return this.drugParameterTitle;
    },
    getDrugParameterScore() {
      return this.drugParameterScore;
    },
    getDrugParameterGenotype() {
      return this.drugParameterGenotype;
    },
    getDrugParameterInfo() {
      return this.drugParameterInfo;
    },
    getPatientTherapistArray() {
      return this.patientArray;
    },
    getPatientName() {
      return this.patientName;
    },
    getPatientNameListObject() {
      return this.patientNameObjectById;
    },
    getTherapistName() {
      return this.therapistName;
    },
    getTestId() {
      return this.testId;
    },
    getTestDate() {
      return this.testDate;
    },
    getTherapistId() {
      return this.therapistId;
    },
    userLogin() {
      if (this.userInfo) {
        return true;
      } else {
        return false;
      }
    },
    getUser() {
      return this.userInfo;
    },
    getIsAdmin() {
      return this.isAdmin;
    },
    getError() {
      return this.errorArray;
    },
    getSelectedTableName() {
      return this.selectedTableName;
    },
    getSelectedTableAttributes() {
      return this.selectedTableAttributes;
    },
    getTableNameList() {
      let tablesName = Object.keys(this.tablesSpecJson);
      return tablesName;
    },
    getTableOptions() {
      return this.tableOptions;
    },
    getIsCacheLoading() {
      return this.isCacheLoading;
    },
  },
});

export default useStore;
