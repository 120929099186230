import { errorHelper } from "../errorHelper";
import { getManyRequest } from "../request/getMany";
import { ENDPOINT } from "./config";

const FINAL_ENDPOINT = ENDPOINT + "/vw_patient_attributes";

export async function getPatientAttributesByTestId(patientId, testId) {
  let filter =
    "q=patient_id:" +
    encodeURI(patientId) +
    "," +
    "test_id:" +
    encodeURI(testId);
  let response = await getManyRequest(FINAL_ENDPOINT, filter);
  let result = errorHelper(response);

  return result;
}
