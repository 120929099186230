import { createApp } from "vue";
import App from "./App.vue";
import VueApexCharts from "vue3-apexcharts";
import { createPinia } from "pinia";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import router from "./router";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faArrowLeft);
const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
app.component("ApexChart", VueApexCharts);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");
